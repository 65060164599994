import { check } from 'istypes';
import { handleActions } from 'redux-actions';
import { getStrictlyDescription } from '../../actions';
import { ApiError } from '../../actions-axios';
import { customErrorMessage, dismissError, notificationMsg } from './actions';
export { customErrorMessage, dismissError, notificationMsg };

const notificationsAndErrorsSet = new Set([]);

const wipeUpSet = new Set([
  String(notificationMsg),
  //  String(warningMsg)
]);

/**
 * @param action
 * @returns {*}
 */
export function registerAlertWorthyAction(action) {
  if (notificationsAndErrorsSet.has(String(action))) {
    return action;
  }
  notificationsAndErrorsSet.add(String(action));
  return action;
}

function getNotificationDefaults() {
  const timestamp = 0.0;
  const dtTimestamp = new Date();
  const type = '';
  const color = 'danger';
  const message = '';
  return { timestamp, type, color, message };
}

export class Notification {
  constructor({ timestamp, type, color, message, dtTimestamp, duration }) {
    Object.assign(this, getNotificationDefaults(), { timestamp, type, color, message, dtTimestamp, duration });
  }
}

function checkIfActionWorthy({ type, payload }) {
  return ((payload instanceof ApiError) || (payload instanceof Notification) ||
    notificationsAndErrorsSet.has(type) || (payload instanceof Error));
}

function extractFromPayload(payload) {
  console.log(payload);
  // + ' ' + ((error.response && error.response.data && error.response.data.message) ? error.response.data.message :
  // (error.message || error.data || ''))
  if (ApiError.isApiError(payload)) {
    return String(payload);
  }
  return payload.message;
}

const defaultState = [];
const ns = 'errors';

const basicHandlers = handleActions({
  [ notificationMsg ]: (state, {
    type,
    payload: { message, color = 'info' },
    meta: { timestamp, dtTimestamp, duration }
  }) => {
    return [ new Notification({
      timestamp,
      dtTimestamp,
      type,
      color,
      message,
      duration
    }), ...state ];
  },
  [ customErrorMessage ]: (state, action) => {
    return [ new Notification({
      timestamp: action.meta.timestamp,
      dtTimestamp: action.meta.dtTimestamp,
      type: action.type,
      color: 'danger',
      message: extractFromPayload(action.payload),
      duration: action.meta.duration,
    }), ...state ];
  },

  [ dismissError ]: (state, { payload: { timestamp, now, duration } }) => {
    return state.filter(({ timestamp: itemTs, duration }) => duration ?
      (!(itemTs === (timestamp - duration)) && !(((itemTs + duration) + duration) >= now)) :
      (!(itemTs === timestamp) && !((itemTs + duration) >= now)));
  }
}, defaultState);

export const alertsReducer = {
  [ ns ]: (state = defaultState, action) => {
    if (!checkIfActionWorthy(action)) {
      return basicHandlers(state, action);
    }

    const { type, payload, error: errorOrFlag, meta: { previousAction } = { previousAction: {} } } = action;
    const { meta } = previousAction || {};
    const { timestamp = performance.now(), dtTimestamp } = meta || { timestamp: performance.now() };

    const description = getStrictlyDescription(previousAction) || getStrictlyDescription(action) || '';
    const payloadError = ((typeof errorOrFlag === 'boolean') ? payload : errorOrFlag) || {};
    let message = extractFromPayload(payloadError) || (description ? `${ description } failed` : 'Generic error');

    if (check.isFunction(message)) {
      message = message(meta, payloadError);
    }

    return [ new Notification({
      timestamp,
      dtTimestamp: dtTimestamp || new Date(new Date(new Date() - 0 - timestamp) - 0 + timestamp),
      type,
      color: 'danger',
      message
    }), ...state ];

  }
};

export const accessErrorsList = () => ({ [ ns ]: state }) => state;

/**
 * Redux connect function's arguments for two separate front-end libraries
 */
export const connectorArgsForErrorAlerts = [
  // mapStateToProps
  (...args) => ({
    errors: accessErrorsList()(...args)
  }),
  // mapDispatchToProps
  dispatch => ({
    handleDismiss: (timestamp, duration) => dispatch(dismissError(timestamp, duration))
  })
];
