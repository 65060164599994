import check from 'istypes';
import { applyForEachProp } from 'shared-package/functional/core';

/**
 * For each key in the supplied object, assuming it's a function with result { url, method? } tries
 * to see if `url` starts with the action verb (GET|POST|etc.) and cut it out into the `method` property.
 * @param obj
 * @returns {{}}
 */
export const retagAll = applyForEachProp(retag);

/**
 *
 * @param fn
 * @return {{(...[*]): [undefined,*]|{method: (string|*), url: (string|*)}, (): [undefined,*]|{method: (string|*), url: (string|*)}}}
 */
function retag(fn) {
  return check.isFunction(fn) ?
    (...args) => repackEndpointProperties(fn(...args)) :
    () => repackEndpointProperties(fn);
}

function repackEndpointProperties(obj) {
  if (Array.isArray(obj)) {
    const [ firstArg, parent ] = obj;
    return [ repackEndpointProperties(firstArg), parent ];
  }

  const { method: rawMethod, url: incomingUrl } = check.isString(obj) ? { url: obj } : obj;
  const result = splitUrl(incomingUrl, rawMethod);
  if (result.method === 'GET') {
    addTimestamp(result);
  }
  if (check.isObject(obj)) {
    Object.assign(result, { ...obj, ...result });
  }
  return result;
}

function splitUrl(rawUrl, rawMethod = 'GET') {
  const [ method, url ] = /^[A-Z]+\s+.+$/.test(rawUrl) ? rawUrl.split(/\s+/) : [ rawMethod, rawUrl ];
  return {
    method,
    url
  };
}

function addTimestamp(obj) {
  const ts = { _t: String(new Date().getTime()).substr(2) };
  obj.params ? Object.assign(obj.params, ts) : Object.assign(obj, { params: ts });
}

export function makePayload(fn, ...args0) {
  return (...args) => {
    const result = fn(...args);
    if (Array.isArray(result)) {
      const [ request, client = {}, parent = {} ] = result;
      return ({
        ...parent,
        payload: {
          ...client,
          request: Object.assign({}, request, ...args0)
        }
      });
    }

    return ({
      payload: {
        request: Object.assign({}, result, ...args0)
      }
    });
  };
}

export function makeTimestampedMeta(...args) {
  const timestamp = performance.now();
  return {
    meta: Object.assign({
      timestamp,
      dtTimestamp: new Date(new Date(new Date() - 0 - timestamp) - 0 + timestamp),
    }, ...args)
  };
}
