import { createAction } from 'redux-actions';

function* actionTypesGen() {
  let i = 0;
  for (; ;) {
    yield `ACT_${ (i++).toString(36).toUpperCase() }`;
  }
}

const actionTypesCtx = {
  current: null
};

function getActionTypesIterator() {
  return actionTypesCtx.current || (actionTypesCtx.current = actionTypesGen());
}

export const nextActionType = () => getActionTypesIterator().next().value;

const DESCRIPTION_SYM = Symbol('description');

export const actionType = (description, ...args) => {
  const type = nextActionType();
  const actionCreator = createAction(type, ...args);
  return Object.assign((...args1) => Object.assign(actionCreator(...args1), {
    [ DESCRIPTION_SYM ]: description
  }), actionCreator, {
    [ DESCRIPTION_SYM ]: description
  });
};

export function prepareActionTypes(fn, count, description) {
  if (!Number.isFinite(count) && (description == null)) {
    description = count;
    count = 1;
  }
  const types = Array(count).fill(true).map(nextActionType);
  return Object.assign((...args) => Object.assign({
    types,
  }, fn(...args), {
    [ DESCRIPTION_SYM ]: description

  }), {
    [ DESCRIPTION_SYM ]: description,
    [ Symbol.iterator ]: function* () {
      yield* types[ Symbol.iterator ]();
    }
  });
}

export const getDescription = (action) => action[ DESCRIPTION_SYM ] || action.type || String(action);
export const getStrictlyDescription = (action) => action?.[ DESCRIPTION_SYM ];
export const createPerfTimestampForMeta = ({ duration } = {}) => {
  const timestamp = performance.now();
  return ({
    timestamp,
    dtTimestamp: new Date(new Date(new Date() - 0 - timestamp) - 0 + timestamp),
    ...(duration ? { duration } : {})
  });
};
