import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { dismissModal, showModal } from '../../../model/packs/modals';

class NamedModalLinkClass extends Component {
  render() {
    //noinspection JSUnusedLocalSymbols
    const { link, invoke, rest, children } = this.props;
    if (link) {
      return (<Button { ...rest } onClick={ invoke } color="link" style={ { padding: 0 } }>{ children }</Button>);
    }
    return (<Button { ...rest } onClick={ invoke }>{ children }</Button>);
  }
}

NamedModalLinkClass.propTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.bool
};

export const NamedModalLink = connect(state => state, (dispatch, { name }) => ({
  invoke: () => dispatch(showModal(name))
}))(NamedModalLinkClass);

class NamedModalClass extends Component {
  render() {
    const { header, headerProps, children: modalBody, bodyProps = {}, footer, footerProps, children, modalState: { active: isOpen }, dismiss, ...rest } = this.props;

    return (<Modal isOpen={ isOpen } toggle={ dismiss } { ...rest }>
      { header ? (<ModalHeader toggle={ dismiss } { ...headerProps }>{ header }</ModalHeader>) : null }
      <ModalBody { ...bodyProps }>{ modalBody }</ModalBody>
      { footer ?
        (<ModalFooter { ...footerProps }>{ footer }</ModalFooter>) :
        (<ModalFooter { ...footerProps }>
          <Button color="secondary" onClick={ dismiss }>Dismiss</Button>
        </ModalFooter>)
      }
    </Modal>);
  }
}

NamedModalClass.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired
};

export const NamedModal = connect(({ modals }, { name }) => ({
  modalState: (modals || {})[ name ] || { active: false }
}), (dispatch, { name }) => ({
  dismiss: () => dispatch(dismissModal(name))
}))(NamedModalClass);
