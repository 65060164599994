import { useEffect, useRef, useState } from 'react';

export function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  });

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [ delay ]);
}

export function useCountDown(timeout, delay = 0) {

  const initValue = ((delay === 0) || (timeout === 0)) ? 0 : Math.ceil( timeout / delay );

  const [ remainingCount, setRemainingCount ] = useState(initValue);

  useInterval(() => {
    if (remainingCount < 0) {
      return;
    }
    setRemainingCount(remainingCount - 1);
  }, (timeout === 0) ? 0 : delay);

  return remainingCount;
}