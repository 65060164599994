import cx from 'classnames';
import React from 'react';
import { Col, FormFeedback, FormGroup, Input, Label, FormText } from 'reactstrap';

export const renderField = ({
                              input,
                              label,
                              type,
                              meta: { touched, error, submissionError, ...etc },
  postfixContent,
                              ...props
                            }) => {

  const printableError = Array.isArray(error) ? error.map((err) => (
    <div key={ err }>{ err }</div>)) : error;

  return (<FormGroup row>
    <Label xs={ 12 } md={ 4 }>{ (type === 'checkbox') ? ' ' : label }</Label>
    <Col xs={ 12 } md={ 8 }>
      {
        (type === 'checkbox') ?
          (
            <FormGroup check>
              <Input { ...input } type={ type } id="checkboxId" invalid={ touched && !!error } className={ cx(input.className, { 'is-invalid': touched && !!error }) } />
              <Label check for="checkboxId" { ...props }>{ props.children }</Label>
              { touched && (error && <FormFeedback>{ printableError }</FormFeedback>) }

            </FormGroup>) :
          (<React.Fragment>
            <Input { ...input } placeholder={ label } type={ type } { ...props } invalid={ touched && !!error } />
            { touched && (error && <FormFeedback>{ printableError }</FormFeedback>) }
            {
              postfixContent &&  <FormText color="muted">{ postfixContent }</FormText>
            }
          </React.Fragment>)
      }
    </Col>


  </FormGroup>);
};
