import { isProduction } from '../constants';

export const navigateToApps = isProduction ?
  () => dispatch => {
    void dispatch;
    window.location = '/apps/index.html';
  } :
  () => dispatch => {
    void dispatch;
    window.alert('Stop the login app, start the apps app.');
    window.location = '/apps';
  };
