/// <reference path="testidHelpers.d.ts" />

export function makeTestIdAssistTuple(...args) {
  const attr = 'data-testid';
  return {
    toTestId,
    get testid() { return toTestId(); },
    toSelector,
    get selector() { return toSelector(); },
    toProps,
    get props() { return toProps(); },
    toTailFromTestId,
    toCustomProps,
    toSelectorForAttr
  };

  function toTestId(...args0) {
    return produceTestIdAttrValue([ ...args, ...args0 ]);
  }

  function toProps(...args0) {
    return toCustomProps(attr, ...args0);
  }

  function toCustomProps(attr, ...args0) {
    return ({ [ attr ]: toTestId(...args0) });
  }

  function toTailFromTestId(testId, ...args0) {
    const testIdHead = toTestId(...args0);
    if (String(testId).startsWith(testIdHead)) {
      const result = String(testId).replace(testIdHead, '').replace(/\|$/, '');
      if (result) {
        return result.split('|');
      }
    }
    return [];
  }

  function toSelector(...args0) {
    return toSelectorForAttr(attr, ...args0);
  }

  function toSelectorForAttr(attr, ...args0) {
    const sel = toTestId(...args0);
    if (sel) {
      return `[${ attr }^="${ escapeDQuote(sel) }"]`;
    }
    return `[${ attr }=""]`;
  }

}

export const e2eAttrs = (...args) => makeTestIdAssistTuple(...args).props;
export const e2eAria = (...args) => makeTestIdAssistTuple(...args).toCustomProps('aria-label');

export function produceTestIdAttrValue(args) {
  const selector = pickHeadUntilNullish(args).map(String).join('|');
  return selector ? selector + '|' : selector;
}


export function pickHeadUntilNullish(arr) {
  const firstEmptyIdx = arr.findIndex(i => i == null);
  return (firstEmptyIdx < 0) ? arr : arr.slice(0, firstEmptyIdx);
}

// https://gist.github.com/getify/3667624
export function escapeDQuote(str) {
  return (String(str || '')).replace(/\\([\s\S])|(")/g, '\\$1$2');
}

function convertSourceWithMapFn(source, mapFn) {
  return Object.fromEntries(Array.from(Object.entries(source), mapFn).filter(Boolean));
}

const keyPairToProps = ([ k, v ]) => ([ k, v.props ]);

const keyPairToExtendableProps = ([ k, v ]) => ([ k, v.toProps ]);

const keyPairToPropsAndHelpers = ([ k, v ]) => ([ k, {
  get value() { return v.props },
  specify: v.toProps,
  specifyWithAttr: v.toCustomProps
} ]);

const keyPairToSelectorAndSpecify = ([ k, v ]) => ([ k, Object.assign(v.selector, {
  specify: v.toSelector
}) ]);

const keyPairToSelectorAndHelpers = ([ k, v ]) => ([ k, Object.assign(v.selector, {
  specify: v.toSelector,
  specifyWithAttr: v.toSelectorForAttr,
  testid: v.testid,
  toTestId: v.toTestId,
  toTailFromTestId: v.toTailFromTestId,
  props: v.props,
  toProps: v.toProps,
  toPropsWithAttr: v.toCustomProps
}) ]);

const keyPairToTestidAndSpecify = ([ k, v ]) => ([ k, Object.assign(v.testid, {
  specify: v.toTestId
}) ]);

const keyPairToTestidTail = ([ k, v ]) => ([ k, v.toTailFromTestId ]);

const keyPairToTestidAndHelpers = ([ k, v ]) => ([ k, Object.assign(v.testid, {
  specify: v.toTestId,
  selector: v.selector,
  toSelector: v.toSelector,
  toSelectorForAttr: v.toSelectorForAttr,
  toTailFromTestId: v.toTailFromTestId,
  props: v.props,
  toProps: v.toProps,
  toPropsWithAttr: v.toCustomProps
}) ]);

export function makeUiTestAssistFromTuples(defineTuples) {
  if (typeof defineTuples !== 'function') {
    throw new Error(`expected type: function, instead got: ${ typeof defineTuples }`);
  }

  const source = defineTuples(makeTestIdAssistTuple);

  return {
    toRenderHelpers,
    toUiTestHelpers,
    toUnitTestHelpers,
    get props() {
      return convertSourceWithMapFn(source, keyPairToProps);
    },
    get extendableProps() {
      return convertSourceWithMapFn(source, keyPairToExtendableProps);
    },
    get tails() {
      return convertSourceWithMapFn(source, keyPairToTestidTail);
    },
    get selectors() {
      return convertSourceWithMapFn(source, keyPairToSelectorAndSpecify);
    },
    get testids() {
      return convertSourceWithMapFn(source, keyPairToTestidAndSpecify);
    },
  };

  function toRenderHelpers() {
    return convertSourceWithMapFn(source, keyPairToPropsAndHelpers);
  }

  function toUiTestHelpers() {
    return convertSourceWithMapFn(source, keyPairToSelectorAndHelpers);
  }

  function toUnitTestHelpers() {
    return convertSourceWithMapFn(source, keyPairToTestidAndHelpers);
  }
}

/**
 *
 * @param {SourceArrayGlossaryObject} obj
 * @return {GlossaryAPI}
 */
export function makeUiTestAssist(obj) {
  return makeUiTestAssistFromTuples(fn => convertSourceWithMapFn(obj, ([ k, v ]) => {
    if (!Array.isArray(v)) {
      return null;
    }
    return [ k, fn(...v) ];
  }));
}


export function appendTestIdAttrValue(props, ...args) {
  return convertSourceWithMapFn(props, ([ k, v ]) =>
    ([ k, k === 'data-testid' ?
      `${ v }${ args.map(String).join('|') }|`.replace(/\|\|/g, '|').replace(/^\|$/g, '') :
      v ]));
}
