import { actionType, createPerfTimestampForMeta } from '../actions';
import { handleActions } from 'redux-actions';

const SYM_MUTE = Symbol('MUTE');

export class ApiError extends Error {
  /**
   *
   * @param code:Number|string|{code:Number,message,humanMessage,internalError}
   * @param message?
   * @param humanMessage?
   * @param internalError?
   */
  constructor(code, message, humanMessage, internalError) {
    super();
    Object.assign(this, { code, message, humanMessage, internalError });
  }

  getStatus() {
    return Number(this.code);
  }

  setHumanMessage(humanMessage) {
    return Object.assign(this, { humanMessage });
  }

  toString() {
    return this[ SYM_MUTE ] ? '' : this.humanMessage;
  }
}

Object.assign(ApiError, {
  isApiError(input) {
    return !!(input && (input instanceof ApiError));
  },
  /**
   *
   * @param copy: { code, message, humanMessage, internalError }|Object
   * @param mapper?:Function
   * @returns {ApiError}
   */
  from(copy, mapper) {
    if (copy == null) {
      return null;
    }
    const { code, message, humanMessage, internalError } = mapper ? mapper(copy) : copy;
    return new ApiError(code, message, humanMessage, internalError);
  },
  mute(apiError) {
    return Object.assign(apiError, { [ SYM_MUTE ]: true });
  }
});

export const apiErrorAction = actionType(`Any API call that results in an error`,
  K => K, createPerfTimestampForMeta);


const ns = 'apiErrors';
const reducer = {
  [ ns ]: handleActions({
    [ apiErrorAction ]: (state, { payload, meta }) => {
      const [ {
        requestId,
        xAmznRequestId,
        error,
        ...errorInfo
      }, {
        timestamp
      } ] = [ payload, meta ];

      return {
        ...state,
        ...((requestId || xAmznRequestId) ? {
          requestId,
          xAmznRequestId,
          history: state.history.set(timestamp, {
            requestId,
            xAmznRequestId
          }),
          errorMap: state.errorMap.set(requestId, error)
        } : { requestId: null, xAmznRequestId: null }),
        ...(error ? { error } : { error: null }),
        ...errorInfo,
        timestamp
      };
    }
  }, {
    requestId: null, xAmznRequestId: null, error: null, timestamp: 0,
    history: new Map(), errorMap: new Map()
  })
};

export default reducer;

export const accessLastErroredRequestId = () => ({ [ ns ]: state }) => state.requestId;
export const accessLastErroredXAmznRequestId = () => ({ [ ns ]: state }) => state.xAmznRequestId;
export const accessLastRecordedError = () => ({ [ ns ]: state }) => state.error;
export const accessLastRecordedTimestamp = () => ({ [ ns ]: state }) => state.timestamp;
