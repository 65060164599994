import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import 'url-search-params-polyfill';
import EmailValidationPage from '../EmailValidationPage';
import LoginPage from '../LoginPage';
import ResetPasswordPage from '../ResetPasswordPage';

export function Routes() {

  return (<Switch>
    <Route path="/login" component={ LoginPage } />
    <Route path="/signup" component={ LoginPage } />
    <Route path="/validate" component={ EmailValidationPage } />
    <Route path="/reset" component={ ResetPasswordPage } />
    <Redirect to="/login" />
  </Switch>);
}

export default Routes;

