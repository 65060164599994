import 'bootstrap/dist/css/bootstrap.css';
import ReactDOM from 'react-dom';
import * as url from 'react-router-redux';
import { appStart } from './model/actions/appCycle';
import { navigateToApps } from './model/actions/navigation';
import { getRootNode } from './root';
import './ui/components/index.css';
import * as ls from 'redux-effects-localstorage';

if (process.env.NODE_ENV !== 'production') {
  //analytics.disable();
}

const { root, store } = getRootNode(/* restoreState(INVALIDATE_STORAGE_AFTER) */);

store.dispatch(appStart);

(async dispatch => {

  const token = await dispatch(ls.getItem('token'));
  const [ , redirectTo ] = (await dispatch(ls.getItem('redirectTo')) || '').split('|');
  console.log('redirectTo:', redirectTo);
  const redirectToUrl = new URL(redirectTo, window.location.origin);
  const { pathname } = redirectToUrl;
  switch (pathname.replace(/\/$/g, '')) {
    case '/login':
    case '/signup':
    case '/validate':
    case '/reset': {
      await dispatch(ls.removeItem('token'));
      console.log('redirectToUrl', redirectToUrl.pathname);
      await dispatch(ls.removeItem('redirectTo'));
      dispatch(url.replace(redirectTo));
      break;
    }
    default: {
      if (token) {
        dispatch(navigateToApps());
        return;
      }
      break;
    }
  }

  const reactRoot = window.document.getElementById('root');
  ReactDOM.render(root, reactRoot);

})(store.dispatch);

//registerServiceWorker();
window.addEventListener('beforeunload', function (evt) {
  //  //saveState(store.getState());
  //unregisterServiceWorker();
});
