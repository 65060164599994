import React from 'react';
import { connect } from 'react-redux';
import { Button, Col, Form, FormGroup } from 'reactstrap';
import { Field, getFormSubmitErrors, reduxForm, stopSubmit } from 'redux-form';
import { UI_TEST_ASSIST, e2eAttrs } from 'shared-package-frontend/testids';
import { featureToggle } from '../../../services/featureToggle';
import { loginValidation } from '../../../services/validation';
import { renderField } from '../../elements/field';

export const LoginForm = ({
                            error,
                            handleSubmit,
                            pristine,
                            reset,
                            submitting,
                            onForgotPassword,
                            submitErrors = {}
                          }) => {

  const { errors: { _error: formError } = {} } = submitErrors;
  const isPasswordResetEnabled = featureToggle.isPasswordResetEnabled();

  return (
    <Form onSubmit={ handleSubmit } { ...UI_TEST_ASSIST.FRM_LOGIN }>

      <Field type="text" name="email" placeholder="something@idk.cool" label="Email" autoComplete="email" component={ renderField } />
      <Field type="password" name="password" label="Password" autoComplete="current-password" component={ renderField } />
      { (error || formError) && <FormGroup row>
        <Col sm={ 12 } md={ 8 } className="offset-md-4"><span className="text-danger small"><strong>{ error ? (error.message ? error.message : JSON.stringify(error)) : formError }</strong></span></Col>
      </FormGroup> }
      <FormGroup row>
        <Col sm={ 4 } className="d-none d-md-block" />
        <div className="col-6 col-md-4 text-left">
          <Button type="button" disabled={ pristine || submitting } onClick={ reset }>Clear</Button>
        </div>
        <div className="col-6 col-md-4 text-right">
          <Button type="submit" color="primary" disabled={ submitting } className=""
            { ...UI_TEST_ASSIST.BTN_SIGNIN }>Sign In</Button>
        </div>
      </FormGroup>
      <FormGroup row>
        <Col md={ 4 } className="d-none d-md-block" />
        <Col xs={ 12 } md={ 8 }>
          <hr />
          <div className="text-center">
            {
              isPasswordResetEnabled ?
                <Button color="link" size="sm" onClick={ onForgotPassword } disabled={ submitting } { ...e2eAttrs('button', 'password reset request') }>Forgot Password?</Button> :
                <Button color="link" size="sm" onClick={ onForgotPassword } disabled>Forgot Password? <sup>(WIP)</sup></Button>
            }
          </div>
        </Col>

      </FormGroup>
    </Form>
  );
};

const FORM_NAME = 'login';

export default reduxForm({
  // a unique name for the form
  form: FORM_NAME,
  validate: loginValidation,
  asyncBlurFields: [ 'email', 'password' ]
})(connect((state) => ({
  submitErrors: getFormSubmitErrors(FORM_NAME)(state)
}))(LoginForm));

export const setLoginErrors = (err) => stopSubmit(FORM_NAME, err);
