import * as storage from 'redux-effects-localstorage';
import { AUTH_KEY } from '../constants';

export function storeToken(token) {
  return storage.setItem(AUTH_KEY, token);
}

export function cleanToken() {
  return storage.removeItem(AUTH_KEY);
}

export function getToken() {
  return storage.getItem(AUTH_KEY);
}