import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import * as url from 'react-router-redux';
import { Card, Col, FormGroup, Jumbotron, Label, Modal, ModalBody, Row } from 'reactstrap';
import * as ls from 'redux-effects-localstorage';
import { SubmissionError } from 'redux-form';
import { unwrapPayloadAndError, unwrapPayloadAndThrowIfError } from 'shared-package-frontend/actions-axios';
import { ApiError } from 'shared-package-frontend/actions-axios/apiError';
import { notificationMsg } from 'shared-package-frontend/packs/alerts';
import { e2eAttrs } from 'shared-package-frontend/testids';
import { exchangeGoogleAuthCode, performLogin, performSignup, requestPasswordReset } from '../../../model/actions/api';
import { navigateToApps } from '../../../model/actions/navigation';
import { cleanToken, getToken, storeToken } from '../../../model/actions/token';
import { PRE_AUTH_KEY } from '../../../model/constants';
import { accessIsSignupLocked, lockSignupForm, releaseSignupForm } from '../../../model/packs/forms';
import { Divider } from '../../elements/divider';
import LoginForm, { setLoginErrors } from '../LoginForm';
import SignupForm, { emptySignupForm, initializeSignupForm, LegalModals, setSignupErrors } from '../SignupForm';
import { ForgotPasswordForm } from './forgotPasswordForm';
import './LoginPage.scss';
import { GoogleSigninIdentityButton } from '../GoogleSigninIdentityButton';
//import GoogleLogin from 'react-google-login';

export const LoginPageV2 = ({ match, location, }) => {

  const isLogin = /login/i.test(match.url);
  const signupLocked = useSelector(accessIsSignupLocked()); // ui.signupLocked,
  //  const showError = useSelector(accessShowError()); //: ui.showError,
  //  const error = useSelector(accessError()); //: ui.error

  const dispatch = useDispatch();
  //  const handleDismissError = useCallback(() => dispatch(dismissError()), [ dispatch ]);
  const handleLogin = useCallback(({ email, password }) => dispatch(performLogin(email, password)), [ dispatch ]);
  const handleSignup = useCallback((payload) => dispatch(performSignup(payload)), [ dispatch ]);
  const handleGoogle = useCallback((credential, clientId) => dispatch(exchangeGoogleAuthCode(credential, clientId)), [ dispatch ]);
  const handleToApps = useCallback(() => dispatch(navigateToApps()), [ dispatch ]);
  const persistToken = useCallback((token) => dispatch(storeToken(token)), [ dispatch ]);
  const wipeToken = useCallback(() => dispatch(cleanToken()), [ dispatch ]);
  const queryToken = useCallback(() => dispatch(getToken()), [ dispatch ]);
  const persistPreAuth = useCallback((preAuth) => dispatch(ls.setItem(PRE_AUTH_KEY, preAuth)), [ dispatch ]);
  const persistUserData = useCallback((userInfo) => dispatch(ls.setItem('userInfo', userInfo)), [ dispatch ]);
  const reportError = useCallback((err) => /login/i.test(match.url) ?
    dispatch(setLoginErrors(err)) :
    dispatch(setSignupErrors(err)), [ dispatch, match.url ]);
  const handleRequestPasswordResetLink = useCallback((email) => dispatch(requestPasswordReset(email)), [ dispatch ]);
  const handleLockSignupForm = useCallback((values) => dispatch(lockSignupForm(values)), [ dispatch ]);
  const handleToValidate = useCallback(() => dispatch(url.push('/validate')), [ dispatch ]);
  const handleReleaseSignupForm = useCallback(() => {
    dispatch(initializeSignupForm());
    dispatch(emptySignupForm());
    return dispatch(releaseSignupForm());
  }, [ dispatch ]);
  const handleShowNotification = useCallback((...args) => dispatch(notificationMsg(...args)), [ dispatch ]);

  const onForgotPassword = useCallback(() => {
    console.log('Forgot password clicked.');
    setShowForgotPassword(true);
  }, []);

  const onCancelForgotPassword = useCallback(() => {
    console.log('Dismissing Forgot password.');
    setShowForgotPassword(false);
  }, []);

  const [ showForgotPassword, setShowForgotPassword ] = useState(false);

  /**
   *
   * authType: "true"
   * email: "andrew.revinsky@digitalarrowtech.com"
   * emailVerified: false
   * isRegistered: true
   * name: "Andrew Revinsky"
   * preAuth:
   * "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7ImVudGl0eVR5cGVOYW1lIjoibmV0LmNocmlzcmljaGFyZHNvbi5taWNyb3NlcnZpY2VzLmFzc2Vzc21lbnQucGxhdGZvcm0uVXNlciIsImNyZWF0ZWQiOjE1NTE3ODg4MTIwMDksIm1vZGlmaWVkIjoxNTUxNzg4ODEyMDA5LCJuYW1lIjoiQW5kcmV3IFJldmluc2t5IiwiZW1haWwiOiJhbmRyZXcucmV2aW5za3lAZGlnaXRhbGFycm93dGVjaC5jb20iLCJ1c2VybmFtZSI6IiIsInBhc3N3b3JkIjoiYzhlMzUyNGQ0MzVkY2FjNjBkNWI2MTVmMmUzM2QyYTQxMzliYWY3MjQ4MTNlYzhlOTZiNDJiY2MyNjM1YzRiZGJiZDAyZDA4MGM4ZWNkNjkwYWYzNGZlMjkzMTg1MmZmZGI4ZWNiN2EyOTFjNmE5MmZhYzUwMjIwZjZiNjRkN2IiLCJzYWx0IjoiZDI0MDAxODdhYzk1YjRhMiIsImF1dGhUeXBlIjoidHJ1ZSIsImRlbGV0ZWQiOmZhbHNlLCJpZCI6ImJhOTZhNmQ2LTg2ZGEtNDc0MC04ZmZlLTAzMjljZWVlZWQ0YiIsInN0YXRlIjoiUEVORElOR19FTUFJTF9WQUxJREFUSU9OIiwidmFsaWRhdGlvbklkIjoiYzZmNDJkNjctM2NkNC00NGZhLWE2ODAtYTA3N2I0YTk4NDRkIn0sImlhdCI6MTU1MTc4ODgxMn0.og92cSkkz0Pxn2pbcRI0X3G-xt7WTLOFUeXvc-8VE-0"
   * success: true token: null
   **/
  const processPayload = useCallback(payload => {

    const {
      data: {
        id,
        //success = false,
        token,
        jwtToken,
        preAuth,
        name = '',
        roles,

        //isRegistered,
        //userId = '',
        //username = '',
        email = '',
        emailVerified = false,
        authType = '',
        authenticitySignature = ''
      } = {}
    } = payload;

    if (authenticitySignature) {
      handleLockSignupForm({ authenticitySignature, authType, name, email, id });
      return;
    }

    if (token || jwtToken) {
      let combinedToken = token || jwtToken;
      persistToken(combinedToken);
      persistUserData({ id, name, email, emailVerified, roles, authType: authType || null });

      handleToApps(location);
      return;
    }

    if (preAuth) {
      persistPreAuth(preAuth);
      persistUserData({ id, name, email, emailVerified, roles, authType: authType || null });
      handleToValidate();
    }
  }, [ handleLockSignupForm, handleToApps, handleToValidate, location, persistPreAuth, persistToken, persistUserData ]);

  const loginSubmit = useCallback(async (values) => {
    // print the form values to the console
    wipeToken && wipeToken();

    const { confirmPassword: _, ...etc } = values;
    const [ err, loginResult ] = await unwrapPayloadAndError(handleLogin(etc));

    if (err && !ApiError.isApiError(err)) {
      debugger;
    }

    if (ApiError.isApiError(err)) {

      if (err.code === 403) {
        throw new SubmissionError({
          _error: String(err),
          message: String(err)
        });
      }
      return;
    }

    processPayload(loginResult);

  }, [ handleLogin, processPayload, wipeToken ]);

  const signupSubmit = useCallback(async (values) => {
    //console.log(values);

    // TODO: remove ASAP, when API fixed
    if (!values[ 'authType' ]) {
      values[ 'authType' ] = 'regular';
    }

    wipeToken && wipeToken();
    const [ _err, signupResult ] = await unwrapPayloadAndError(handleSignup(values));
    if (_err) {
      console.log(_err);
      debugger;
      const { internalError: err } = _err;

      //const { response: { data } = {}, message = '' } = err;
      if (intersection(new Set([ 'email', 'name', 'password', 'confirmPassword', 'consentGiven' ]), new Set(Object.keys(err))).size > 0) {
        throw Object.assign(new SubmissionError(err), { errors: err });
      }
      throw new SubmissionError({
        _error: String(_err),
        message: String(_err)
      });
    }

    processPayload(signupResult);
  }, [ handleSignup, processPayload, wipeToken ]);


  const onRequestResetLink = useCallback(async (formData, formApi) => {
    const { email } = formData;
    const [ err ] = await unwrapPayloadAndError(handleRequestPasswordResetLink(email));
    if (err) {
      return;
    }

    // https://blog.prototypr.io/ux-guide-password-reset-user-flow-bfa35a16e527
    handleShowNotification({ message: `Password reset link has been sent. Please click it when you get an email.` });

    setTimeout(formApi.reset, 0);
    onCancelForgotPassword();
  }, [
    handleRequestPasswordResetLink, handleShowNotification, onCancelForgotPassword ]);

  const handleGoogleResponseGSI = useCallback(async (credential, clientId) => {

    try {
      const googleResponse = await unwrapPayloadAndThrowIfError(handleGoogle(credential, clientId));
      processPayload(googleResponse);
    } catch (err) {
      const {
        response: { data } = {},
        message = ''
      } = err;

      const newErr = new SubmissionError({
        _error: data,
        message
      });

      reportError(newErr);
    }

    //const { hg, accessToken, googleId: gId, profileObj, tokenId, tokenObj } = rest;
    //const { email, familyName, givenName, googleId, imageUrl, name } = profileObj;
    //console.log(accessToken, tokenId, tokenObj, gId, googleId, email, familyName, givenName, imageUrl, name);
    //console.log(hg.id_token);


  }, [ handleGoogle, processPayload, reportError ]);

  const onResponseFromGoogle = useCallback(({ credential, clientId }) => {
    // https://developers.google.com/identity/gsi/web/reference/js-reference#credential
    return handleGoogleResponseGSI(credential, clientId);
  }, [ handleGoogleResponseGSI ]);

  const verifyTokenIsPresentElseRedirect = useCallback(async () => {
    const token = await queryToken();
    if (token) {
      handleToApps();
    }
  }, [ handleToApps, queryToken ]);

  useEffect(() => {
    void verifyTokenIsPresentElseRedirect();
  }, [ verifyTokenIsPresentElseRedirect ]);

  if (showForgotPassword) {
    return (<Modal isOpen={ true } centered backdrop={ false } fade={ false } className="modal-no-border">
      <ModalBody>
        <h3 className="mb-0">Forgot Password?</h3>
        <em className="d-block text-muted text-info mb-3">This can happen to the best of us.</em>

        <ForgotPasswordForm onSubmit={ onRequestResetLink } onCancel={ onCancelForgotPassword } />

      </ModalBody>
    </Modal>);
  }

  return (
    <>
      <Jumbotron className="text-center py-2 pt-4 mt-4 mb-2">
        {/*<h1 className="display-5">The Microservice Architecture Assessment</h1>*/ }
        <p className="lead ">Assess what you have built and how to improve it</p>
        <p className="lead ">Maximize the benefits of the microservice architecture</p>
        <p className="lead ">Reduce architectural and organizational risk</p>
        <p className="lead ">
          <a href="https://microservices.io/platform/microservice-architecture-assessment.html" target="_blank" rel="noopener noreferrer">Learn more..</a>
        </p>
      </Jumbotron>
      <Row className="d-flex justify-content-center">
        <Col sm={ 8 } className="p-3">

          <h3>{ isLogin ? 'Sign in:' : (signupLocked ? 'Registering using Google:' : 'Register:') } </h3>

          <h4><p /></h4>
          <Card className="p-4">
            {
              signupLocked ? null : (<>
                <div className="">
                  <FormGroup row>
                    <Label md={ 4 } />
                    <Col xs={ 12 } md={ 8 } className="text-left">
                      <GoogleSigninIdentityButton
                        onCredentialResponse={ onResponseFromGoogle }
                      />
                      {/*<GoogleLogin*/ }
                      {/*  clientId={ process.env.REACT_APP_GOOGLE_CLIENT_ID }*/ }
                      {/*  //autoLoad={ true }*/ }
                      {/*  //isSignedIn={ true }*/ }
                      {/*  responseType="code"*/ }
                      {/*  style={ {} }*/ }
                      {/*  buttonText=" "*/ }
                      {/*  onSuccess={ responseGoogle }*/ }
                      {/*  onFailure={ failureGoogle }*/ }
                      {/*  className="btn-custom-google-signin mx-auto"*/ }
                      {/*/>*/ }
                    </Col>
                  </FormGroup>
                </div>
                <Divider className="pb-3">or</Divider>
              </>)
            }
            { isLogin ?
              (<LoginForm onSubmit={ loginSubmit } onForgotPassword={ onForgotPassword } />) :
              (<SignupForm onSubmit={ signupSubmit } onReset={ handleReleaseSignupForm } />)
            }
            {/*{*/ }
            {/*  <Alert color="danger" isOpen={ showError && error } toggle={ handleDismissError }>*/ }
            {/*    { error && <span className="text-danger">{ error }</span> }*/ }
            {/*  </Alert>*/ }
            {/*}*/ }
          </Card>
          <div className="clearfix p-3" />
          <Card className="p-4">
            {
              isLogin ?
                (
                  <div className="text-center">New to Microservice Architecture Assessment? <Link to={ '/signup' }
                    { ...e2eAttrs('link-to-signup') }>Create an account</Link>.
                  </div>) :
                (<div className="text-center">Already registered? <Link to={ '/login' }>Sign in</Link>.</div>)
            }
          </Card>
          <LegalModals />
        </Col>
      </Row>
    </>
  );
};

export default LoginPageV2;

/**
 *
 * @param setA: {Set<*>>}
 * @param setB: {Set<*>}
 * @returns {Set<*>}
 */
function intersection(setA, setB) {
  return new Set(Array.from(setB.values()).filter(val => setA.has(val)));
}

