//import styled from 'styled-components';
import styled from '@emotion/styled';
import React from 'react';

const DividerWrapper = styled.span`
  overflow: hidden;
  text-align: center;
`;

const DividerArms = styled.span`
  position: relative;
  padding: 16px;
  
  &::before {
    content: '';
    position: absolute;
    border-bottom: 1px solid ${ ({ lineColor = '#e4e4e4' }) => lineColor };
    top: 50%;
    right: 100%;
    width: 5000px;
}
  &::after {
    content: '';
    position: absolute;
    border-bottom: 1px solid ${ ({ lineColor = '#e4e4e4' }) => lineColor };
    top: 50%;
    left: 100%;
    width: 5000px;
}
`;

const DivierContents = styled.span`
    //font-weight: normal;
    margin: 0px;
    word-wrap: break-word;
    //font-family: Circular,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,sans-serif;
    font-weight: 100;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.2px;
    padding-top: 0px;
    padding-bottom: 0px;
    color:  ${ ({ color = '#767676' }) => color };
    display: inline;
`;

export const Divider = ({ color, lineColor, className, ...rest }) =>
  (<DividerWrapper className={ className }>
    <DividerArms lineColor={ lineColor }><DivierContents color={ color } { ...rest } /></DividerArms></DividerWrapper>);
