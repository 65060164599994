import React, { useEffect, useRef, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { Form as BsForm, FormFeedback, FormGroup, Label } from 'reactstrap';
import { e2eAttrs } from 'shared-package-frontend/testids';
import { changePasswordValidation as validate } from '../../services/validation';

export const PasswordChangeForm = ({ onSubmit }) => {

  const inputEl = useRef(null);
  const [ needFocus, setNeedFocus ] = useState(true);
  useEffect(() => {
    if (inputEl.current && needFocus) {
      inputEl.current.focus();
      setNeedFocus(false);
    }
  }, [ needFocus ]);

  return <Form
    onSubmit={ onSubmit }
    //validateOnBlur={ true }
    validate={ validate }>{
    ({ handleSubmit, pristine, submitting }) => {
      return (
        <BsForm onSubmit={ handleSubmit } { ...e2eAttrs('form', 'password change') }>
          <Field name="password" render={ ({ input, meta }) => {
            return (
              <FormGroup>
                <Label for="txtPassword">New password:</Label>
                <input type="password" id="txtPassword" { ...input } className={ `form-control ${ (meta.dirty && ((meta.error && !meta.active) || meta.submitError)) ? 'is-invalid' : '' }` } placeholder="New Password" autoComplete="one-time-code" ref={ inputEl } readOnly={ submitting } />
                { ((meta.error && !meta.active) || meta.submitError) && meta.dirty &&
                <FormFeedback invalid="true">{ String(meta.error || meta.submitError) }</FormFeedback> }
              </FormGroup>
            );
          } } />
          <Field name="confirmPassword" render={ ({ input, meta }) => {
            return (
              <FormGroup>
                <Label for="txtConfirmPassword">Retype password:</Label>
                <input type="password" id="txtConfirmPassword" { ...input } className={ `form-control ${ (meta.dirty && ((meta.error && !meta.active) || meta.submitError)) ? 'is-invalid' : '' }` } placeholder="Confirm Password" autoComplete="one-time-code" readOnly={ submitting } />
                { ((meta.error && !meta.active) || meta.submitError) && meta.dirty &&
                <FormFeedback invalid="true">{ String(meta.error || meta.submitError) }</FormFeedback> }
              </FormGroup>
            );
          } } />
          <button type="submit" disabled={ pristine || submitting } className="btn btn-primary" { ...e2eAttrs('submit', 'password change') }>Change</button>
        </BsForm>);
    }
  }</Form>;
};
