import { initialize, pageview } from 'react-ga';
import { together } from 'shared-package/functional';

const trackingId = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_CODE;
const doDebug = ((debugRaw) => isNaN(Number(debugRaw)) ? debugRaw === 'true' : Boolean(Number(debugRaw)))(process.env.REACT_APP_GOOGLE_ANALYTICS_DEBUG);


export const trackHistory = (history) => {

  if (!trackingId) {
    return;
  }

  startGa();

  history.listen((location, action) => {
    const currentUrl = `${ location.pathname }${ location.search }${ location.hash }`;
    trackGa(currentUrl);

    if (doDebug) {
      console.log(`The last navigation action was ${ action }`, JSON.stringify(history, null, 2));
    }
  });
};

const initialized = makeResolvable();

const startGa = () => {
  console.log('Using Google Tracking Id:', trackingId);

  if (!trackingId) {
    return;
  }

  initialized.resolve(() => initialize(trackingId, { debug: doDebug }));
};

const trackGa = (path) => {
  if (!trackingId) {
    return;
  }

  return (async () => {

    await initialized;
    if (doDebug) {
      console.log('tracking page: ', path);
    }
    pageview(path);

  })();

};


function makeResolvable() {
  let resolve = (fn) => {
    fn && fn();
  };
  const result = new Promise(rs => resolve = (fn) => together(fn, rs)());
  Object.assign(result, { resolve });
  return result;
}
