import { range } from './range';

const MSECS_IN_SEC = 1000;
const MSECS_IN_MIN = MSECS_IN_SEC * 60;
const MSECS_IN_HOUR = MSECS_IN_MIN * 60;
const MSECS_IN_DAY = MSECS_IN_HOUR * 24;
const MSECS_IN_COMMON_YEAR = MSECS_IN_DAY * 365;
const MSECS_IN_LEAP_YEAR = MSECS_IN_DAY * 366;

export const getExpirationStage = (when, now, lambda) => {
  const diff = when - now;
  return 1 + ((diff > 0 && diff < lambda) ? 0 : Math.sign(now - when));
};

export const DateMsecIn = {
  SEC: MSECS_IN_SEC,
  MIN: MSECS_IN_MIN,
  HOUR: MSECS_IN_HOUR,
  DAY: MSECS_IN_DAY,
  COMMON_YEAR: MSECS_IN_COMMON_YEAR,
  LEAP_YEAR: MSECS_IN_LEAP_YEAR
};

export const isLeapYear = year => ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0);

export const leapYearCountBetween = (dateStart, dateEnd) =>
  range(new Date(dateStart).getFullYear(), new Date(dateEnd).getFullYear())
    .filter(isLeapYear)
    .map(yyyy => new Date(`${ yyyy }-02-29T12:00:00Z`))
    .filter(ld => ((dateStart <= ld) && (ld < dateEnd))).length;

export const getNextAnniversary = (dateStart, dateNow) => {
  const yyyy = new Date(dateNow).getFullYear();

  const dateForDateStart = new Date(dateStart).toISOString().substr(5,5);

  return ([ yyyy, yyyy + 1]
    .map(yyyy => new Date(`${ yyyy }-${ dateForDateStart }T00:00:00Z`))
    .filter(d => d > dateNow))[0];
};
