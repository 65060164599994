// @ flow

import { isFunction, isObject } from './typeChecks';

// curry :: ((a, b, ...) -> c) -> a -> b -> ... -> c
/**
 * https://mostly-adequate.gitbooks.io/mostly-adequate-guide/appendix_a.html#curry
 * @param fn
 * @param arityHint
 * @returns {function(...[*]): (*)}
 */
export function curry(fn /* : MyCurriedFunction<any, any> */, arityHint /* : * */) /* : function */ {
  const arity = typeof arityHint === 'undefined' ? fn.length : arityHint;

  return function $curry(...args) {
    if (args.length < arity) {
      return $curry.bind(null, ...args);
    }

    return fn.call(null, ...args);
  };
}

// compose :: ((a -> b), (b -> c),  ..., (y -> z)) -> a -> z
export const compose = (...fns /* : Array<function> */) /* : function */ =>
  (...args) => fns.reduceRight((res, fn) => [ fn.call(null, ...res) ], args)[ 0 ];

// inspect :: a -> String
// https://mostly-adequate.gitbooks.io/mostly-adequate-guide/appendix_a.html#inspect
export const inspect = (x) => {
  if (x && typeof x.inspect === 'function') {
    return x.inspect();
  }

  function inspectFn(f) {
    return f.name ? f.name : f.toString();
  }

  function inspectTerm(t) {
    switch (typeof t) {
      case 'string':
        return `'${ t }'`;
      case 'object': {
        const ts = Object.keys(t).map(k => [ k, inspect(t[ k ]) ]);
        return `{${ ts.map(kv => kv.join(': ')).join(', ') }}`;
      }
      default:
        return String(t);
    }
  }

  function inspectArgs(args) {
    return Array.isArray(args) ? `[${ args.map(inspect).join(', ') }]` : inspectTerm(args);
  }

  return (typeof x === 'function') ? inspectFn(x) : inspectArgs(x);
};

export const propValueAssign = curry((propName, propValue, obj) => isObject(obj) ? Object.assign(obj, { [ propName ]: propValue }) : obj);

const arrayMap = curry((arr, mapFn) => arr.map(mapFn));
export const arrayPreMap = curry((mapFn, arr) => arr.map(mapFn));

export const filter = curry((filterFn, source) => (Array.isArray(source) ? source : Array.from(source || [])).filter(filterFn));

const objectMap = (obj, mapFn) =>
  Array.from(Object.entries(obj))
    .reduce((obj, [ k, v ]) =>
      propValueAssign(k, mapFn(v), obj), {});
//    propValueAssign(k, conditional(isFunction, preappliedFn, identity)(v), obj), {});

export const constant = K => () => K;
export const identity = arg => arg;
export const map = curry((mapFn, arrOrObj) =>
  Array.isArray(arrOrObj) ?
    arrOrObj.map(mapFn) :
    objectMap(arrOrObj, mapFn));

export const withArgs = function withArgs(...args) { return fn => fn(...args); };
export const withArgsExtra = function withArgsExtra(...args) { return fn => (...extraArgs) => fn(...args, ...extraArgs); };

export const apply = curry((fn, arg) => fn(arg));
export const preApply = curry((arg, fn) => fn(arg));
export const apply2 = curry((fn, arg0, arg1) => fn(arg0, arg1));
export const apply3 = curry((fn, arg0, arg1, arg2) => fn(arg0, arg1, arg2));

export const eq = (fn1, fn2) => (...args) => fn1(...args) === fn2(...args);
export const eqConstant = (k, fn2) => (...args) => k === fn2(...args);
export const and = (fn1, fn2) => (...args) => fn1(...args) && fn2(...args);
export const or = (fn1, fn2) => (...args) => fn1(...args) || fn2(...args);
export const not = fn => (...args) => !fn(...args);

/**
 *
 * @returns (x: K) => K
 */
export const applyMethods = curry((obj, arg) => objectMap(obj, preApply(arg)));

export const isAll = curry((testFn, arr) => arr.filter(testFn).length === arr.length);
export const isAny = curry((testFn, arr) => arr.length ? arr.filter(testFn).length > 0 : false);

export const safelyExecuteFnSync = (resultFn, fallbackFn) => (...args) => {
  try {
    return resultFn(...args);
  } catch (ex) {
    return fallbackFn(ex, ...args);
  }
};

export const conditional = curry((testFn, thenFn, elseFn, subject) => {
  try {
    if (testFn(subject)) {
      return thenFn(subject);
    }
  } catch {}
  return (elseFn || identity)(subject);
});

/**
 * Higher-order function, calls all functions supplied in the first call (last to first)
 * with the arguments of the second call. Returns the results of the first function.
 * @param fns
 * @returns {function(...[*]): *}
 */
export function together(...fns) {
  return fns.length ? function (...args) {
    return fns.reduceRight((_, fn) => fn(...args), null);
  } : (K => K);
}

/**
 * Creates a higher-order function that executes the initial function passed in a first call
 * against each key-value pair passed in the second call, the pair is passed as the first and a second parameter
 * @param fn
 * @returns {function(*=): *[]}
 */
export const batch = (fn) => obj => Array.from(Object.entries(obj)).map(arr => fn(...arr));

export const applyForEachProp = curry((fn, obj) =>
  Array.from(Object.entries(obj)).reduce((cur, [ k, v ]) => Object.assign(cur, { [ k ]: fn(v) }), {}));

export const applyEachFor = curry((arr, defaultFn, obj) => arr.map(fn => (isFunction(fn) ?
  fn(obj) :
  (isFunction(defaultFn) ?
    defaultFn(obj) :
    defaultFn))));
