import React from 'react';
import { Provider } from 'react-redux';
import { trackHistory } from './services/ga';
import getStore from './configureStore';
import { ConnectedRouter } from 'connected-react-router';
import { App as AppLayout } from './ui/components/AppLayout/App';

export function getRootNode(initialState) {

  const { store, history } = getStore(initialState);

  trackHistory(history);

  const root = (
    <Provider store={ store }>
      <ConnectedRouter history={ history }>
        <AppLayout />
      </ConnectedRouter>
    </Provider>
  );

  return {
    root,
    store
  };

}
