import { actionType, createPerfTimestampForMeta } from '../../actions';

export const dismissError = actionType('Dismissing alert directly or by timeout',
  (timestamp, timeout) => ({ ...createPerfTimestampForMeta(), timestamp, now: performance.now() }));

export const customErrorMessage = actionType('Custom error message',
  k => k, createPerfTimestampForMeta);

export const notificationMsg = actionType('Display notification',
  k => k, createPerfTimestampForMeta);
