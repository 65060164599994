import React, { useCallback, useEffect } from 'react';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';
import { e2eAttrs } from '../../../testids';
import TimeAgo from 'react-timeago';
import { useTimeoutFn } from 'react-use';
import { DateMsecIn } from 'shared-package/utils/dates';
import './alerts.scss';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

/**
 *
 * expected test hooks: [data-role=alert][data-role-description=info|danger]
 * @param props
 * @returns {*}
 * @constructor
 */
export function GenericAlerts({ errors, handleDismiss }) {
  return (<TransitionGroup component={ ErrorWrap }>
    {
      errors.map(({ dtTimestamp, timestamp, message, type, duration, color = 'danger' }) => {
        // color: // info | danger
        if ((message === undefined) || (message === 'undefined') || (String(message) === 'undefined')) {
          debugger;
          return null;
        }

        // color: // info | danger
        return message ? <CSSTransition key={ `${ timestamp }_${ type }` } classNames="Alerts-fade" timeout={ {
          enter: 500,
          exit: 300
        } }><AlertImpl toast { ...{
          timestamp,
          dtTimestamp,
          duration,
          type,
          color,
          handleDismiss,
          message
        } } /></CSSTransition> : null;
      })
    }
  </TransitionGroup>);

}

const RELEASE_ALERT_AFTER_MSEC = 30 * DateMsecIn.SEC;

function AlertImpl({
                     dtTimestamp,
                     timestamp,
                     type,
                     color,
                     duration = RELEASE_ALERT_AFTER_MSEC,
                     handleDismiss,
                     message,
                     toast
                   }) {

  const timevalue = dtTimestamp; // new Date(new Date(new Date() - 0 - timestamp) - 0 + timestamp);

  const ariaProps = color === 'danger' ?
    { role: 'alert', 'aria-live': 'assertive' } :
    { role: 'status', 'aria-live': 'polite' };

  const title = {
    'info': 'Information',
    'danger': 'Error occurred'
  }[ color ] || 'Message';

  const dismissAlert = useCallback(() => handleDismiss(timestamp, RELEASE_ALERT_AFTER_MSEC), [ timestamp ]);

  const [ , cancelTimeout ] = useTimeoutFn(dismissAlert, duration || RELEASE_ALERT_AFTER_MSEC);

  useEffect(() => cancelTimeout, [ cancelTimeout ]);

  return toast ? (<div className="p-1 my-2 rounded">
    <Toast { ...ariaProps } aria-atomic="true" { ...e2eAttrs('alert', color) } className="toast-mod">
      <ToastHeader icon={ color } toggle={ dismissAlert } tag={ HeaderMultiTag }>
        {
          [ title,
            <small key={ '2' } className="mr-2"><TimeAgo date={ timevalue } /></small>
          ]
        }
      </ToastHeader>
      <ToastBody>
        { message }
      </ToastBody>
    </Toast>
  </div>) : (<Alert color={ color }
    toggle={ dismissAlert } transition={ transitionConfig }
    { ...e2eAttrs('alert', color) }>{ message }</Alert>);
}

function HeaderMultiTag({ className, children }) {
  return React.Children.map(children, (child, idx) => {
    if (idx === 0) {
      return <strong className={ className }>{ child }</strong>;
    }
    return child;
  });
}


const transitionConfig = {
  enter: false,
  exit: false,
  appear: false,
  timeout: { enter: 500, leave: 300, appear: 500 }
};

const ErrorWrap = (props) => (<div className="Alerts--wrap" aria-live="assertive" { ...props } />);

const EnterLeaveAlertsTransition = (props) => {
  return <TransitionGroup { ...props } />;
  //  return (<ReactCSSTransitionGroup
  //    transitionName="Alerts-fade"
  //
  //    transitionEnterTimeout={ 500 }
  //    transitionLeaveTimeout={ 300 }
  //    transitionAppearTimeout={ 500 }
  //
  //    transitionAppear={ true }
  //    transitionEnter={ true }
  //    transitionLeave={ true }
  //
  //    component={ ErrorWrap }
  //
  //    { ...props }
  //  />);
};

