import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import * as url from 'react-router-redux';
import { Modal, ModalBody } from 'reactstrap';
import * as storage from 'redux-effects-localstorage';
import { notificationMsg } from 'shared-package-frontend/packs/alerts';
import { getSearchParam } from '../../../helpers/url';
import { updatePassword, validateResetPasswordToken } from '../../../model/actions/api';
import { PasswordChangeForm } from '../../elements/PasswordChangeForm';
import { Spinner } from '../../elements/spinner';
import { unwrapPayloadAndError } from 'shared-package-frontend/actions-axios';

export const ResetPasswordPage = ({ queryResetTokenId, handleDropQuery, handleToLogin, handleShowNotification, persistKeyValue, removePersistedKey, readPersistedValueByKey, handleValidateToken, handleUpdatePassword, ...etc }) => {

  const validationRef = useRef(null);
  const tokenRestorationRef = useRef(null);
  const [ validatedToken, setValidatedToken ] = useState(null);

  const onSubmit = useCallback(async ({ password, confirmPassword }, { reset, focus, ...formApi }) => {
    console.log(password, confirmPassword, formApi);
    debugger;

    const [ err ] = await unwrapPayloadAndError(handleUpdatePassword(password, validatedToken));
    if (err) {
      debugger;

      focus('password');
      return { password: String(err) };
    }

    debugger;
    handleShowNotification({ message: `Your password has been updated successfully! Please try to log in.` });
    handleToLogin({ replace: true });

  }, [ handleShowNotification, handleToLogin, handleUpdatePassword, validatedToken ]);

  useEffect(() => {
    validationRef.current || (validationRef.current = queryResetTokenId ? (async () => {
      const [ err, payload ] = await unwrapPayloadAndError(handleValidateToken(queryResetTokenId));
      if (err) {
        console.log(err);
        debugger;
        handleShowNotification({ message: `The password reset link has expired.` });
        handleToLogin({ replace: true });
        return;
      }
      console.log(payload);
      debugger;
      persistKeyValue('queryResetTokenId', queryResetTokenId);
      handleDropQuery();
    })() : null);
  }, [ handleDropQuery, handleShowNotification, handleToLogin, handleValidateToken, persistKeyValue, queryResetTokenId ]);

  useEffect(() => {
    tokenRestorationRef.current || (tokenRestorationRef.current = !queryResetTokenId ? (async () => {
      const token = await readPersistedValueByKey('queryResetTokenId');
      await removePersistedKey('queryResetTokenId');
      if (!token) {
        debugger;
        handleShowNotification({ message: `The password reset link was malformed.` });
        handleToLogin({ replace: true });
        return;
      }
      console.log(token);
      debugger;
      setValidatedToken(token);
    })() : null);
  }, [ handleShowNotification, handleToLogin, queryResetTokenId, readPersistedValueByKey, removePersistedKey ]);

  if (!validatedToken) {
    return (<Spinner/>);
  }

  console.log(etc);

  return (<Modal isOpen={ true } centered backdrop={ false } fade={ false } className="modal-no-border">
    <ModalBody>
      <h3 className="mb-3">Change Your Password:</h3>
      <PasswordChangeForm onSubmit={ onSubmit }/>

    </ModalBody>
  </Modal>);

};

export default connect((_, { location }) => ({
  queryResetTokenId: getSearchParam(location.search, 'resetToken')
}), (dispatch, location) => ({
  handleDropQuery: () => dispatch(url.replace(location.path)),
  handleToLogin: ({ replace = false } = {}) => dispatch(url[ replace ? 'replace' : 'push' ]('/login')),
  handleShowNotification: (...args) => dispatch(notificationMsg(...args)),
  persistKeyValue: (key, value) => dispatch(storage.setItem(key, value)),
  removePersistedKey: (key) => dispatch(storage.removeItem(key)),
  readPersistedValueByKey: (key) => dispatch(storage.getItem(key)),
  handleUpdatePassword: (password, validatedToken) => dispatch(updatePassword(password, validatedToken)),
  handleValidateToken: (token) => dispatch(validateResetPasswordToken(token))

}))(ResetPasswordPage);
