import axios from 'axios';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, compose, createStore } from 'redux';
import sequential from 'redux-actions-sequences';
import axiosMiddleware from 'redux-axios-middleware';
import localStorage from 'redux-effects-localstorage';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import { ensureEnvVariable } from 'shared-package/env';
import { makeAxiosMiddlewareOptions } from 'shared-package-frontend/actions-axios';
import createRootReducer from './model/reducers';


const apiClient = axios.create({
  //all axios can be used, shown in axios documentation
  baseURL: ensureEnvVariable('REACT_APP_API_URL'), //process.env.REACT_APP_API_URL,
  responseType: 'json',
  crossDomain: true,
  withCredentials: true
});


const history = createBrowserHistory();

const middleware = [ /*  promise, */
  axiosMiddleware(apiClient, makeAxiosMiddlewareOptions(() => '', true)),
  localStorage(window.localStorage),
  sequential,
  thunk,
  routerMiddleware(history),
  process.env.NODE_ENV === 'development' ? createLogger({ diff: true }) : null
].filter(Boolean);

export default function getStore(initialState) {

  const appliedMiddleware =
    applyMiddleware(...middleware);

  const store = compose(appliedMiddleware)(createStore)(createRootReducer(history), initialState);

  //if (module.hot) {
  //  module.hot.accept(() => {
  //
  //    debugger;
  //
  //    const nextRootReducer = combineReducers({
  //      //router: routerStateReducer,
  //      ...(require('./reducersObject').default)
  //    });
  //
  //    store.replaceReducer(nextRootReducer);
  //  });
  //}

  return { store, history };
}
