import { dasherize, escapeDQuote } from './strings';

/**
 *
 * @description https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore/pull/179/files
 * @param obj
 * @param path
 * @param defaultValue
 * @returns {*}
 */

export const getIn = (obj, path, defaultValue) => (Array.isArray(path) ? path : String.prototype.split.call(path, /[.[\]]/).filter(Boolean))
  .reduce((a, c) => (a === defaultValue) ? a : (a && Object.hasOwnProperty.call(a, c) ? a[ c ] : (defaultValue || null)), obj);


/**
 * If the diff object contains keys that are not strictly equal, a new object is created
 * based on the initial object with the diff key-value pairs applied
 * @param obj
 * @param diff
 * @returns {*}
 */
export const applyIfDifferent = (obj, diff) => Object.keys(diff).reduce((cur, k) => (cur && (diff[ k ] === obj[ k ])), true) ? obj : { ...obj, ...diff };

function convertToConventionalNamedProps(obj, prefix, testingPattern) {
  return Object.fromEntries(
    Array.from(Object.entries(obj), ([ k, v ]) =>
      ([ testingPattern.test(k) ? k : `${ prefix }-${ dasherize(k) }`, String(v) ])));
}

/**
 * Takes an object and converts all its props to 'data-*' attributes with string values
 * @param dataObj
 * @returns {any}
 */
export const convertToDataAttrs = (dataObj = {}, prefix) =>
  convertToConventionalNamedProps(dataObj, `data${ prefix ? `-${ prefix }` : '' }`, /^data-/);

export const dataAttrs = convertToDataAttrs;


//export const e2eAttrs = (...args) => {
//  const filteredArgs =  args.filter(arg => arg !== undefined);
//  const [ role, roleDescription, itemName, id ] = filteredArgs;
//  return dataAttrs([ { // 0
//  }, { // 1
//    role
//  }, { // 2
//    role,
//    roleDescription
//  }, { // 3
//    role,
//    roleDescription,
//    itemName
//  }, { // 4
//    role,
//    roleDescription,
//    itemName,
//    id
//  } ][ filteredArgs.length ] || {});
//};
//
//export const e2eDataSelector = (role, roleDescription, itemName) => [
//  makeSelectorFor('data-role', role),
//  makeSelectorFor('data-role-description', roleDescription),
//  makeSelectorFor('data-item-name', itemName)
//].filter(Boolean).join('');
//
//function makeSelectorFor(name, value) {
//  if (value === undefined) {
//    return '';
//  }
//  if (value === null) {
//    return `[${ name }]`;
//  }
//  return `[${ name }="${ escapeDQuote(value) }"]`;
//}
//
//export const e2eAria = (label, roledescription, details) => convertToConventionalNamedProps(details ? {
//  label,
//  roledescription,
//  details
//} : { label, roledescription }, 'aria', /^aria-/);
//
//export const e2eAriaSelector = (label, roledescription, details) => [
//  label !== undefined ? `[aria-label="${ escapeDQuote(label) }"]` : '',
//  roledescription !== undefined ? `[aria-roledescription="${ escapeDQuote(roledescription) }"]` : '',
//  details !== undefined ? `[aria-details="${ escapeDQuote(details) }"]` : '',
//].filter(Boolean).join('');

//escapeDQuote(text)
