import React, { Component } from 'react';
import { Container, Navbar, NavbarBrand } from 'reactstrap';
import ErrorAlerts from '../ErrorAlerts';

import Routes from '../Routes';
import './App.css';

export class App extends Component {
  render() {
    return (
      <div className="App">
        <Navbar dark className="bg-dark">
          <Container>
            <div className="navbar-header">
              <NavbarBrand href="/" className="mr-auto">Microservice Architecture Assessment Platform</NavbarBrand>
            </div>
            <div className="float-right">
              { /*<AuthButton />*/ }
            </div>

            <ErrorAlerts />
          </Container>
        </Navbar>

        <Container>
          <Routes />
        </Container>

      </div>
    );
  }
}
