import { checkPasswordStrength } from './password';

export const signupValidation = ({ email, password, confirmPassword, consentGiven }) => {
  const errors = {};
  let tmp, isValid;
  if (!email) {
    errors.email = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
    //errors.email = 'Invalid email address'
  }
  if (!password) {
    errors.password = 'Please fill out the password';
  } else if (({ isValid, error: tmp } = checkPasswordStrength(password)), !isValid) { // eslint-disable-line
    errors.password = tmp;
  }
  if (confirmPassword !== password) {
    errors.confirmPassword = 'Does not match password';
  }
  if (!consentGiven) {
    errors.consentGiven = 'Please agree to the Terms & Policy';
  }
  return errors;
};

export const changePasswordValidation = ({ password, confirmPassword }) => {
  debugger;

  const errors = {};
  let tmp, isValid;
  if (!password) {
    errors.password = 'Please fill out the password';
  } else if (({ isValid, error: tmp } = checkPasswordStrength(password)), !isValid) { // eslint-disable-line
    errors.password = tmp;
  }

  if (confirmPassword !== password) {
    errors.confirmPassword = 'Does not match password';
  }
  return errors;

};

export const loginValidation = ({ email, password }) => {
  const errors = {};
  if (!email) {
    errors.email = 'Required';
  }
  if (!password) {
    errors.password = 'Required';
  }
  return errors;
};
