import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-final-form';
import { Field } from 'react-final-form-html5-validation';
import { Button, Col, Form as BsForm, FormFeedback, FormGroup, FormText, Input, Label } from 'reactstrap';
import { e2eAttrs } from 'shared-package-frontend/testids';

export const ForgotPasswordForm = ({ onSubmit, onCancel }) => {

  const inputEl = useRef(null);
  const [ needFocus, setNeedFocus ] = useState(true);
  useEffect(() => {
    if (inputEl.current && needFocus) {
      inputEl.current.focus();
      setNeedFocus(false);
    }
  }, [ needFocus ]);

  return (<Form onSubmit={ onSubmit } render={ ({ handleSubmit, pristine, submitting, invalid }) => (
    <BsForm onSubmit={ handleSubmit }  { ...e2eAttrs('form', 'password reset request') }>
      <FormGroup>
        <Label>Your email:</Label>
        <Field name="email" render={ ({ input, meta }) =>
          <Input type="email" { ...input } innerRef={ inputEl } disabled={ submitting } /> } />
        <FormText>Request password reset link by supplying your email address.</FormText>
      </FormGroup>
      { invalid && <FormGroup>
        <FormFeedback invalid="true">Form is invalid</FormFeedback>
      </FormGroup> }

      <FormGroup row>
        <Col xs={ 6 } className="text-left">
          <Button type="button" disabled={ submitting } onClick={ onCancel }  { ...e2eAttrs('cancel', 'password reset request') }>Cancel</Button>
        </Col>
        <Col xs={ 6 } className="text-right">
          <Button type="submit" color="primary" disabled={ invalid || pristine || submitting } className=""  { ...e2eAttrs('submit', 'password reset request') }>Request</Button>
        </Col>
      </FormGroup>
    </BsForm>
  ) } />);
};
