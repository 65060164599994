import { makeUiTestAssist } from '../ui-test-assist';
// EXPORT !!
export { e2eAttrs, e2eAria } from '../ui-test-assist';

export const {

  props: UI_TEST_ASSIST,
  extendableProps: UI_TEST_ASSIST_EXT,
  selectors: UI_SELECTOR,
  testids: UI_TESTID,
  tails: UI_TESTID_TAIL,
  ...E2E_HELPERS

} = makeUiTestAssist({

  NAV_BREADCRUMBS: [ 'navigation', 'breadcrumbs' ],
  BC_SYS_OPERATION: [ 'breadcrumb', 'system operation' ],

  FRM_LOGIN: [ 'form', 'login' ],

  BTN_SIGNIN: [ 'signin-button' ],

  COND_TABLE_NO_ITEM: [ 'table', 'no items' ],
  CTL_USER_MENU_TOGGLE: [ 'user-menu-popup-toggle' ],
  CTL_ORG_SELECTOR_TOGGLE: [ 'dd toggle', 'organizations selector' ],
  CTL_ORG_SELECT_PERSONAL_MODE: [ 'dd item', 'organizations selector', '' ],
  CTL_ORG_SELECTOR_NO_ITEM: [ 'dd no item', 'organizations selector' ],
  CTL_LINK_TO_PROFILE_PAGE: [ 'dd item', 'link to profile page' ],

  CTL_ASSESSMENT_SECTION_LIST_ITEM: [ 'assessment-section-list-item' ],
  TXT_ASSESSMENT_SECTION_TITLE: [ 'text info', 'nav section title' ],
  LNK_ASSESSMENT_SECTION: [ 'assessment-section-link' ],
  CTL_LINK_ASSESSMENT_SECTION: [ 'assessment-section-link' ],
  CTL_LIST_ITEM_ASSESSMENT: [ 'list item', 'assessment' ],
  BC_LINK_TO_PROFILE_PAGE: [ 'bc item', 'link to profile page' ],
  LINK_TO_ACCESS_KEYS_PAGE: [ 'link', 'to access keys page' ],
  LINK_TO_ORGANIZATIONS_PAGE: [ 'link', 'to organizations page' ],
  LINK_TO_ORG_DETAIL_PAGE: [ 'link', 'to organization detail page' ],
  LINK_TO_APP_PAGE: [ 'link', 'to application' ],
  LINK_TO_SVC_PAGE: [ 'link', 'to service' ],

  LINK_TO_APP_PROGRESS_PAGE: [ 'link', 'progress page', 'application' ],
  LINK_TO_SVC_PROGRESS_PAGE: [ 'link', 'progress page', 'service' ],
  LINK_TO_SYS_OP_PAGE: [ 'link', 'system operation page' ],

  LINK_TO_CHANNEL: [ 'link', 'to channel' ],

  CTL_LINK_TO_ORGANIZATIONS_PAGE: [ 'dd item', 'to organizations page' ],
  CTL_LINK_RESUME_APP_SURVEY: [ 'link button', 'resume app survey' ],
  CTL_LINK_RESUME_SVC_SURVEY: [ 'link button', 'resume svc survey' ],
  CTL_LINK_ABANDON_APP_SURVEY: [ 'link button', 'abandon app survey' ],
  CTL_LINK_ABANDON_SVC_SURVEY: [ 'link button', 'abandon svc survey' ],

  CTL_NAV_LINK_APP_PROGRESS: [ 'nav link', 'to app progress' ],
  CTL_NAV_LINK_SVC_AGG_PROGRESS: [ 'nav link', 'to svc aggregated progress' ],

  PAGE_SVC_ITEM: [ 'page', 'service page' ],
  PAGE_CHANNEL: [ 'page', 'channel' ],
  PAGE_SYS_OPERATION: [ 'page', 'system operation' ],

  PAGE_APP_ITEM: [ 'page', 'application page' ],
  PAGE_PROGRESS: [ 'page', 'progress page' ], // SEL.PAGE_PROGRESS
  PAGE_PROFILE: [ 'page', 'profile page' ], // SEL.PAGE_PROFILE
  PAGE_PART_PROFILE_LANDING: [ 'page part', 'profile landing' ], // SEL.PAGE_PART_PROFILE_LANDING
  PAGE_PART_PROFILE_ACCESS_KEYS: [ 'page part', 'profile access keys' ], // SEL.PAGE_PART_PROFILE_LANDING
  PAGE_PART_PROFILE_ORGS_MGMT: [ 'page part', 'profile organizations management' ], // SEL.PAGE_PART_PROFILE_LANDING
  PAGE_PART_PROFILE_ORG_DETAIL_MGMT: [ 'page part', 'profile organization details page' ], // SEL.PAGE_PART_PROFILE_LANDING

  COGS_TOGGLE_ORG_ACTIONS: [ 'cogs toggle', 'organization actions' ], // SEL.COGS_TOGGLE_ORG_ACTIONS
  CTL_ACTION_SWITCH_TO_ORG: [ 'cogs dd item', 'switch to organization' ],
  CTL_ACTION_CHANGE_ORG_CAPACITY: [ 'cogs dd item', 'change organization capacity' ],
  CTL_ACTION_CHANGE_ORG_PAYMENT: [ 'cogs dd item', 'edit organization payment' ],
  CTL_ACTION_DELETE_ORG: [ 'cogs dd item', 'delete organization' ],

  CTL_ACTION_SEND_INVITE: [ 'cogs dd item', 'send invite to member' ],
  CTL_ACTION_DELETE_MEMBER: [ 'cogs dd item', 'delete member' ],
  CTL_ACTION_INVITE_USER: [ 'modal toggle', 'invite user to paid organization' ],

  MODAL_EDIT_ASSESSMENT_NAME: [ 'modal', 'correct assessment name' ], // SEL.MODAL_EDIT_ASSESSMENT_NAME

  MODAL_TOGGLE_CREATE_ORG_LEGACY: [ 'modal toggle', 'create organization' ], // SEL.MODAL_TOGGLE_CREATE_ORG_LEGACY
  MODAL_TOGGLE_CREATE_ORG: [ 'modal toggle', 'create paid organization' ], // SEL.MODAL_TOGGLE_CREATE_ORG
  MODAL_TOGGLE_ORG_EDIT_CAPACITY: [ 'modal toggle', 'edit capacity' ], // SEL.MODAL_TOGGLE_ORG_EDIT_CAPACITY
  MODAL_TOGGLE_ORG_PAYMENT_INFO: [ 'modal toggle', 'edit payment info' ], // SEL.MODAL_TOGGLE_ORG_PAYMENT_INFO
  MODAL_TOGGLE_ORG_DELETE: [ 'modal toggle', 'delete organization' ], // SEL.MODAL_TOGGLE_ORG_DELETE

  MODAL_TRIGGER_DELETE_APPLICATION: [ 'modal trigger', 'delete application' ],
  MODAL_TRIGGER_RENAME_APPLICATION: [ 'modal trigger', 'rename application' ],
  MODAL_TRIGGER_CHANGE_APP_OWNERSHIP: [ 'modal trigger', 'change app ownership' ],

  MODAL_EDIT_ORG: [ 'modal', 'edit paid organization' ], // SEL.MODAL_EDIT_ORG
  MODAL_CREATE_ORG: [ 'modal', 'create paid organization' ], // SEL.MODAL_CREATE_ORG
  MODAL_CREATE_LEGACY_ORG: [ 'modal', 'create organization' ], // SEL.MODAL_CREATE_LEGACY_ORG
  MODAL_DELETE_ORG: [ 'modal', 'delete organization' ], // SEL.MODAL_DELETE_ORG
  MODAL_DELETE_ACC_KEY: [ 'modal', 'delete access key' ], // SEL.MODAL_DELETE_ORG

  STEP_CAPACITY_CHANGE: [ 'step', 'capacity change' ], // SEL.STEP_CAPACITY_CHANGE
  STEP_PAYMENT: [ 'step', 'payment' ], // SEL.STEP_PAYMENT
  STEP_SUCCESS: [ 'step', 'success' ], // SEL.STEP_SUCCESS
  STEP_FAILURE: [ 'step', 'failure' ], // SEL.STEP_FAILURE

  EL_PLACEHOLDER: [ 'insufficient data placeholder', 'progress' ],

  TEXT_CAUGHT_ERROR: [ 'text info', 'caught error' ],

  TEXT_CURRENT_ORG: [ 'text info', 'current organization' ], // SEL.TEXT_CURRENT_ORG
  TEXT_CURRENT_PERSONAL_MODE: [ 'text info', 'current organization', 'Personal Mode' ], // SEL.TEXT_CURRENT_PERSONAL_MODE
  TEXT_SHOWN_APPS_COUNT: [ 'text info', 'shown applications count' ], // SEL.TEXT_SHOWN_APPS_COUNT_0
  TEXT_SHOWN_APPS_COUNT_0: [ 'text info', 'shown applications count', '0' ], // SEL.TEXT_SHOWN_APPS_COUNT_0
  TEXT_ESTIMATED_PRORATION: [ 'text', 'estimated proration' ], // SEL.TEXT_ESTIMATED_PRORATION
  TEXT_LAST_4_DIGITS: [ 'text', 'last 4 digits' ], // SEL.TEXT_LAST_4_DIGITS

  TEXT_ERROR_LINE: [ 'error line', 'payment method error' ], // SEL.TEXT_ERROR_LINE
  TEXT_ACCESS_KEY_ID: [ 'text', 'access key id' ],
  TEXT_ACCESS_KEY_ID_EMPTY: [ 'text', 'access key id', '' ],

  TEXT_ACCESS_KEY_SECRET: [ 'text', 'access key secret' ],

  TEXT_ACCESS_KEY_SECRET_WARN: [ 'text', 'access key secret keep' ],

  TEXT_QUESTIONNAIRE_INTRO: [ 'text info', 'questionnaire introduction' ],
  TEXT_SURVEY_SECTION_TITLE: [ 'text info', 'survey section title' ],
  TEXT_SURVEY_SECTION_DESCRIPTION: [ 'text info', 'survey section description' ],
  TEXT_SURVEY_QUESTION: [ 'text info', 'survey question' ],
  TEXT_SURVEY_ANSWER: [ 'text info', 'survey answer' ],
  TEXT_SNIPPET_CONTENT: [ 'text', 'snippet content' ],

  HEADING_WITH_ANCHOR_FN_PAGE_NAME: [ 'heading' ],

  BTN_CORRECT_CAPACITY: [ 'button', 'correct capacity' ], // SEL.BTN_CORRECT_CAPACITY
  BTN_EDIT: [ 'button', 'edit' ], // SEL.BTN_EDIT
  BTN_DISMISS: [ 'button', 'dismiss' ], // SEL.BTN_DISMISS
  BTN_SUBMIT_CHOWN_FORM: [ 'submit', 'change owner form' ], // SEL.BTN_SUBMIT_CHOWN_FORM
  BTN_SUBMIT_CREATE_FORM: [ 'submit', 'create form' ], // SEL.BTN_SUBMIT_CREATE_FORM
  BTN_FORM_FOOTER_PRIMARY: [ 'button', 'form footer', 'primary' ], // SEL.BTN_FORM_FOOTER_PRIMARY
  BTN_FORM_FOOTER_DANGER: [ 'button', 'form footer', 'danger' ], // SEL.BTN_FORM_FOOTER_DANGER
  BTN_FORM_FOOTER_DISMISS: [ 'button', 'form footer', 'dismiss' ], // SEL.BTN_FORM_FOOTER_DISMISS
  BTN_APPLY_MEMBERS_EMAIL: [ 'button', 'apply button', 'apply member email' ], //
  BTN_GENERATE_ACCESS_KEY: [ 'button', 'generate access key' ],
  BTN_DELETE_ACCESS_KEY: [ 'button', 'delete access key' ],
  BTN_APP_TAKE_SURVEY: [ 'app-take-survey' ],
  BTN_SVC_TAKE_SURVEY: [ 'svc-take-survey' ],
  BTN_SURVEY_RESET_SECTION: [ 'reset button', 'reset survey' ],
  BTN_SURVEY_FASTWORWARD_FILL_SECTION: [ 'fastforward button', 'fill section' ],
  BTN_SURVEY_FASTWORWARD_FILL_ALL: [ 'fastforward button', 'fill all and finish' ],
  BTN_SURVEY_SAVE_AND_NEXT: [ 'assessment button', 'save and next' ],
  BTN_SNIPPET_COPY: [ 'button', 'copy to clipboard' ],
  BTN_SURVEY_QUESTION_RADIO: [ 'radio', 'survey question' ],
  FLD_SURVEY_QUESTION_NUMERIC: ['field', 'survey question', 'numeric'],

  TAB_DASHBOARD_APP_SERVICES: [ 'tab', 'application page', 'dashboard services' ],
  TAB_DASHBOARD_APP_DIAGRAM: [ 'tab', 'application page', 'dashboard diagram' ],
  TAB_DASHBOARD_APP_MESSAGES: [ 'tab', 'application page', 'dashboard messages' ],

  PANE_DASHBOARD_APP_SERVICES: [ 'pane', 'application page', 'dashboard services' ],
  PANE_DASHBOARD_APP_DIAGRAM: [ 'pane', 'application page', 'dashboard diagram' ],
  PANE_DASHBOARD_APP_MESSAGES: [ 'pane', 'application page', 'dashboard messages' ],

  TAB_SVC_ASSESSMENTS: [ 'tab', 'service page', 'assessments' ],
  TAB_DASHBOARD_SVC_MSG: [ 'tab', 'service page', 'dashboard messages' ],
  TAB_DASHBOARD_SVC_SYS_OPS: [ 'tab', 'service page', 'system operations' ],

  PANE_SVC_ASSESSMENTS: [ 'pane', 'service page', 'assessments' ],
  PANE_DASHBOARD_SVC_MSG: [ 'pane', 'service page', 'dashboard messages' ],
  PANE_DASHBOARD_SVC_SYS_OPS: [ 'pane', 'service page', 'system operations' ],


  FLD_CHOWN_FORM_ORG_ID: [ 'field', 'change owner form', 'organizationId' ], // SEL.FLD_CHOWN_FORM_ORG_ID
  FLD_CREATE_FORM_NAME: [ 'field', 'create form', 'name' ], // SEL.FLD_CREATE_FORM_NAME
  FLD_CHANGE_CAPACITY_MEMBERS_EMAIL: [ 'field', 'change capacity form', 'members email' ], // 'input[name=email][type=email]',

  CHCK_WIP_FEATURES: [ 'checkbox', 'wip features' ],
  CHCK_WIP_FEATURES_ON: [ 'checkbox', 'wip features', '1' ],
  CHCK_WIP_FEATURES_OFF: [ 'checkbox', 'wip features', '0' ],

  ICON_INCOMPLETE: [ 'icon', 'incomplete' ],
  ALERT_INFO: [ 'alert', 'info' ],
  ALERT_DANGER: [ 'alert', 'danger' ],

  BTN_INDICATOR_REFRESH: [ 'refresh-button' ],

  MODAL: [ 'modal' ],
  MODAL_CHOWN: [ 'modal', 'change owner' ],

  TOGGLE_COGS: [ 'cogs toggle' ],

  cogsToggleAppActions: [ 'cogs toggle', 'application actions' ],

  MODAL_TRIGGER: [ 'modal trigger' ],
  modalTriggerAppChown: [ 'modal trigger', 'change app ownership' ],

  CTL_SELECT_ORG_BY_NAME: [ 'dd item', 'organizations selector' ],

  CTL_TAB_ITEM: [ 'nav tabs', 'tab item' ],
  CTL_TAB_CONTENT: [ 'tab content', 'tab pane' ],

  TXT_EXPLANATION_OPTION: [ 'text', 'explanation option' ],

  WIDGET_EXPLANATION: [ 'explanation widget' ],

  MARKER_EXPLANATION_OPTION: [ 'marker', 'option index' ],

  CTL_SIZE_PER_PAGE_FOR_TABLE: [ 'table nav', 'pagination control', 'size per page' ],
  CTL_PAGINATION_FOR_TABLE: [ 'table nav', 'pagination control', 'pagination buttons' ],

  MODAL_DELETE_APP: [ 'modal', 'delete application' ],
  MODAL_DELETE_SVC: [ 'modal', 'delete service' ],
  MODAL_TRIGGER_DELETE_SVC: [ 'modal trigger', 'delete service' ],
  MODAL_TRIGGER_RENAME_SVC: [ 'modal trigger', 'rename service' ],
  MODAL_TRIGGER_DELETE_APP_ASSESSMENT: [ 'modal trigger', 'delete application assessment' ],
  MODAL_TRIGGER_RENAME_APP_ASSESSMENT: [ 'modal trigger', 'rename application assessment' ],
  MODAL_TRIGGER_COPY_APP_ASSESSMENT: [ 'modal trigger', 'copy application assessment' ],
  LIST_APP_ASSESSMENT: [ 'list', 'application assessment list' ],

  BTN_CREATE_SVC: [ 'create-svc-button' ],
  BTN_CREATE_SVC_SECONDARY: [ 'create-svc--secondary-button' ],

  LIST_SERVICES: [ 'list', 'services list' ],

  MODAL_DELETE_APP_ASSESSMENT: [ 'modal', 'delete application assessment' ],

  MODAL_COPY_APP_ASSESSMENT: [ 'modal', 'copy application assessment' ],
  MODAL_RENAME_APP: [ 'modal', 'rename application' ],
  MODAL_RENAME_SVC: [ 'modal', 'rename service' ],
  MODAL_RENAME_APP_ASSESSMENT: [ 'modal', 'rename application assessment' ],

  TBL_APP_METADATA: [ 'table', 'app metadata' ],
  TBL_SVC_METADATA: [ 'table', 'svc metadata' ],

  BTN_ADD_NEW_METADATA: [ 'button', 'add new metadata request' ],
  BTN_SAVE_ADD_NEW_METADATA: [ 'button', 'add new metadata confirm' ],
  BTN_SAVE_EDIT_METADATA: [ 'button', 'edit metadata confirm' ],

  BTN_EDIT_METADATA: [ 'button', 'edit existing metadata' ],
  BTN_DELETE_METADATA: [ 'button', 'delete existing metadata' ],

  INPUT_METADATA_NAME: [ 'input', 'metadata', 'name' ],
  INPUT_METADATA_VALUE: [ 'input', 'metadata', 'value' ],

  MODAL_DELETE_METADATA_PAIR: [ 'modal', 'delete metadata' ],

  TXT_METADATA_FETCHING: [ 'text', 'metadata table', 'fetching' ],
  TXT_METADATA_NO_MESSAGE: [ 'text', 'metadata table', 'no message' ],
  TXT_METADATA_NO_DATA: [ 'text', 'metadata table', 'no data' ],

  TBL_DATA_FETCHING: [ 'text', 'general table', 'fetching' ],
  TBL_DATA_NO_DATA: [ 'text', 'general table', 'no data' ],


  CMPNT_APP_ARCHITECTURE: [ 'component', 'application arch' ],
  ROW_APP_ARCH_TABLE: [ 'row', 'application arch table' ],
  TBL_APP_ARCHITECTURE: [ 'table', 'application arch table' ],

  CMPNT_SVC_MSGS: [ 'component', 'service messages' ],
  CMPNT_SVC_SYS_OP: [ 'component', 'service system operations details' ],

  FLD_APP_FILTER_FORM: [ 'field', 'app filter form', 'filter' ],
  BTN_APP_FILTER_APPLY: [ 'submit', 'app filter form' ],

  SELECT_QUESTION_OPTIONS: [ 'options', 'select' ],
  NUMERIC_QUESTION_OPTIONS: [ 'options', 'numeric' ],
  NUMERIC_WITH_UNITS_OPTIONS: [ 'options', 'numeric', 'units' ],
  STATIC_QUESTION_ANSWER_VALUE: [ 'options', 'static' ],
  ANSWERS_TOGGLER: [ 'answers toggle', 'show answers' ],
  SCORES_EXPLANATION: [ 'scores explanation' ],
  QUESTION_OPTIONS_RESULT: [ 'question options', 'result' ],
});
