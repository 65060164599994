//GoogleSigninIdentityButton

import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const googleUrl = 'https://accounts.google.com/gsi/client';

export const GoogleSigninIdentityButton = ({ onCredentialResponse, }) => {

  const [ scriptLoaded, setScriptLoaded ] = useState(typeof window !== 'undefined' && typeof window.google !== 'undefined');

  const divRef = React.createRef();

  // Helmet does not support the onLoad property of the script tag, so we have to manually add it like so
  const handleChangeClientState = (newState, addedTags) => {
    if (addedTags && addedTags.scriptTags) {
      const foundScript = addedTags.scriptTags.find(({ src }) => src === googleUrl);
      if (foundScript) {
        foundScript.addEventListener('load', () => setScriptLoaded(true), {
          once: true,
        });
      }
    }
  };

  useEffect(() => {
    if (scriptLoaded && divRef.current) {

      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID, // 'YOUR-GOOGLE-CLIENT-ID-HERE',
        callback: onCredentialResponse,
      });

      // https://developers.google.com/identity/gsi/web/reference/js-reference#GsiButtonConfiguration
      window.google.accounts.id.renderButton(divRef.current, {
        theme: 'outline',
        size: 'large',
//        width: divRef.current.clientWidth,
      });
    }
  }, [ scriptLoaded, divRef, onCredentialResponse ]);
  return (
    <>
      <HelmetProvider>
        <Helmet onChangeClientState={ handleChangeClientState }>
          <script src={ googleUrl } async defer />
        </Helmet>
      </HelmetProvider>
      <div ref={ divRef } />
    </>
  );
};

