import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { handleActions } from 'redux-actions';
import { reducer as formReducer } from 'redux-form';
import { alertsReducer as errorReducerObject } from 'shared-package-frontend/packs/alerts';
import handleFormLock from '../packs/forms';
import modals from '../packs/modals';
//import errors from '../packs/errors';

const defaultState = () => ({
  signupLocked: false,
  signupForm: {},
  showError: false,
  error: null
});


const ui = handleActions({

  ...handleFormLock,

}, defaultState());

export default (history) => combineReducers({
  router: connectRouter(history),
  ui,
  modals,
  //  errors,
  form: formReducer,
  ...errorReducerObject
});
