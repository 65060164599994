/**
 *
 * @param str String
 * @returns String
 */
export function dasherize(str) {
  return underscore(str).replace(/_/g, '-');
}

function underscore(str) {
  return str.replace(/::/g, '/')
    .replace(/\./g, '_')
    .replace(/([A-Z]+)([A-Z][a-z])/g, '$1_$2')
    .replace(/([a-z\d])([A-Z])/g, '$1_$2')
    .replace(/-/g, '_')
    .toLowerCase();
}

// https://gist.github.com/getify/3667624
export function escapeDQuote(str) {
  return (String(str) || '').replace(/\\([\s\S])|(")/g,"\\$1$2");
}

export const truncate = str => String(str ?? '').match(/^(.*)$/m)[ 0 ];