import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Button, Col, Form, FormGroup } from 'reactstrap';
import { Field, initialize, reduxForm, reset, stopSubmit } from 'redux-form';
import { e2eAttrs } from 'shared-package-frontend/testids';
import { signupValidation as validate } from '../../../services/validation';
import { renderField } from '../../elements/field';
import { NamedModal } from '../NamedModals';

function getErrorMessage(error) {
  if (!error) {
    return null;
  }
  const message = error.message ? error.message : String(error);
  if ((message === undefined) || (message === 'undefined') || (String(message) === 'undefined') || (String(message) === '[object Object]')) {
    debugger;
    return null;
  }
  return message;
}

export const SignupForm = ({ error, handleSubmit, pristine, reset: defaultResetHandle, onReset, submitting, locked = false }) => {

  const reset = (...args) => {
    onReset && onReset(...args);
    defaultResetHandle(...args);
  };

  return (
    <Form onSubmit={ handleSubmit } { ...e2eAttrs('form', 'registration') }>
      <Field type="hidden" name="authenticitySignature" component="input" />
      <Field type="hidden" name="authType" component="input" />

      <Field type="text" name="email" placeholder="you@example.com" label="Email" autoComplete="email" component={ renderField } disabled={ locked } />
      <Field type="text" name="name" placeholder="Your actual name" label="Name" autoComplete="name" component={ renderField } />
      {/*<Field type="text" name="username" placeholder="Pick a username" label="Username" autoComplete="username" component={ renderField } />*/ }
      { locked ? null : (
        <Field type="password" name="password" label="Password" placeholder="Create a password" autoComplete="new-password" component={ renderField } />) }
      { locked ? null : (
        <Field type="password" name="confirmPassword" label="Confirm Password" placeholder="Repeat password" autoComplete="new-password" component={ renderField } />) }
      <Field type="checkbox" name="consentGiven" component={ renderField }>Check to agree to our <a target="_blank" href="http://chrisrichardson.net/legal/microservices-assessment-terms.html" rel="noopener noreferrer">terms of service</a> and <a target="_blank" href="http://chrisrichardson.net/legal/microservices-assessment-policy.html" rel="noopener noreferrer">privacy policy</a>.</Field>
      { error && <FormGroup row>
        <Col md={ 4 } />
        <Col xs={ 12 } md={ 8 }><strong className="text-danger">{ getErrorMessage(error) }</strong></Col>
      </FormGroup> }
      <FormGroup row>
        <Col md={ 4 } />
        <Col xs={ 12 } md={ 8 }>
          <div className="float-left">
            <Button type="button" disabled={ (pristine || submitting) && !locked } onClick={ reset }>{ locked ? 'Cancel' : 'Clear' }</Button>
          </div>
          <div className="float-right">
            <Button type="submit" color="primary" disabled={ submitting } className=""
              { ...e2eAttrs('signup-button') }>Sign Up</Button>
          </div>
          <div className="clearfix" />
          { /*<div className="text-muted small text-center pt-2">*/ }
          { /*By clicking on "Sign up for MAA", you agree to our terms of service and privacy policy.*/ }
          { /*</div>*/ }

        </Col>

      </FormGroup>
    </Form>
  );
};

SignupForm.propTypes = {
  locked: PropTypes.bool
};

export const LegalModals = () => (<React.Fragment>
  <NamedModal name="terms" header="Terms of service">Text of terms and conditions</NamedModal>
  <NamedModal name="policy" header="Privacy policy">Text of privacy policy</NamedModal>
</React.Fragment>);


export default connect(({ ui: { signupLocked, signupForm } }) => ({
  initialValues: signupLocked ? signupForm : null,
  locked: signupLocked
}), {
  //load:
})(reduxForm({
  // a unique name for the form
  form: 'signup',
  validate,
  asyncBlurFields: [ 'email', 'name', 'password', 'passwordConfirm' ]
})(SignupForm));

export const setSignupErrors = (err) => stopSubmit('signup', err);
export const initializeSignupForm = () => initialize('signup', {
  authenticitySignature: '',
  authType: '',
  email: '',
  name: '',
  password: '',
  confirmPassword: '',
  consentGiven: ''
});
export const emptySignupForm = () => reset('signup');
