import { handleActions } from 'redux-actions';
import { actionType } from 'shared-package-frontend/actions';

export const dismissModal = actionType('Dismiss named modal', (name) => ({ name }));
export const showModal = actionType('Show named modal', (name) => ({ name }));

export default handleActions({

  [ dismissModal ]: (state, { payload: { name } }) => {
    return (name ? [ name ] : Object.keys(state)).reduce((memo, modalName) => {
      return {
        [ modalName ]: {
          ...state[ modalName ],
          active: false
        }
      };
    }, {});
  },
  [ showModal ]: (state, { payload: { name } }) => {
    return {
      ...state,
      [ name ]: {
        ...state[ name ],
        active: true
      }
    };
  },

}, {});
