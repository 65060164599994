import React from 'react';
import { Field, Form } from 'react-final-form';
import { Form as BsForm, FormFeedback, FormGroup, FormText, Label } from 'reactstrap';
import { e2eAttrs } from 'shared-package-frontend/testids';

export const ValidationForm = ({ onSubmit, validate }) => {
  const [ needFocus, setNeedFocus ] = React.useState(true);

  React.useEffect(() => {
    const handler = () => setNeedFocus(true);
    window.addEventListener('focus', handler);
    return () => window.removeEventListener('focus', handler);
  }, []);

  return <Form
    onSubmit={ onSubmit }
    validate={ validate || null }>{
    ({ handleSubmit, pristine, submitting }) => {
      return (
        <BsForm onSubmit={ handleSubmit } { ...e2eAttrs('form', 'email validation') }>
          <Field name="validationId">{
            ({ input, meta }) => {
              return (
                <FormGroup>
                  <Label for="txtValidationCode">Enter Validation Code:</Label>
                  <input type="text" id="txtValidationCode" { ...input } className={ `form-control form-control-lg col-sm-9 col-md-6 ${ (!meta.dirty && meta.submitError) ? 'is-invalid' : '' }` } placeholder="Validation Code" autoComplete="one-time-code" readOnly={ submitting } ref={ el => (needFocus || meta.active) && el && [ el.focus(), setNeedFocus(false) ] } />
                  <FormText>Copy-and-paste your validation code here.</FormText>
                  { meta.submitError && !meta.dirty && <FormFeedback invalid>{ String(meta.submitError) }</FormFeedback> }
                </FormGroup>
              );
            }
          }
          </Field>
          <button type="submit" disabled={ pristine || submitting } className="btn btn-primary">Submit</button>
        </BsForm>);
    }
  }</Form>;
};
