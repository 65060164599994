import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as url from 'react-router-redux';
import * as storage from 'redux-effects-localstorage';
import { unwrapPayloadAndError } from 'shared-package-frontend/actions-axios';
import { notificationMsg } from 'shared-package-frontend/packs/alerts';
import { useInterval } from '../../../helpers/hooks';
import { verboseSeconds } from '../../../helpers/time';
import { getSearchParam } from '../../../helpers/url';
import { emailValidation, requestResend } from '../../../model/actions/api';
import { navigateToApps } from '../../../model/actions/navigation';
import { storeToken } from '../../../model/actions/token';
import { PRE_AUTH_KEY } from '../../../model/constants';
import { Spinner } from '../../elements/spinner';
import { ValidationForm } from '../../elements/ValidationForm';


export const EmailValidationPage = ({ handleRequestValidation, handleRequestResendEmail, handleShowNotification, queryValidationId, queryPreAuth, wipePreAuth, handleDropQuery, handleToApps, handleToLogin, persistToken, persistUserData }) => {

  const [ validationCode, setValidationCode ] = useState(queryValidationId);
  const [ autoValidation, setAutoValidation ] = useState(!!queryValidationId);
  const [ validationPending, setValidationPending ] = useState(false);
  const [ responseResult, setResponseResult ] = useState(null);
  const [ responseError, setResponseError ] = useState(null);
  const [ token, setToken ] = useState(false);

  const [ resendEnabled, setResendEnabled ] = useState(true);
  const [ resendTimestamp, setResendTimestamp ] = useState(0);
  const [ remainingSeconds, setRemainingSeconds ] = useState(0);

  const [ suggestLogin, setSuggestLogin ] = useState(false);

  /**
   * Tick backwards
   * */
  useInterval(() => {
    if (resendTimestamp === 0) {
      return;
    }
    const timeDiff = resendTimestamp - new Date();
    if (timeDiff < 0) {
      setResendEnabled(true);
      setRemainingSeconds(0);
    } else {
      setRemainingSeconds(Math.ceil(timeDiff / 1000));
    }
  }, resendEnabled ? null : 1000);

  /**
   * resend email API
   */
  const makeResendEmailCall = async (token) => {
    setResponseError(null);

    const [ apiError, apiResult ] = await unwrapPayloadAndError(handleRequestResendEmail(token));
    if (apiError) {
      setResponseError(apiError);
      {
        const { internalError: { response } } = apiError;
        //debugger;
        setResponseResult({ ...response, meta: { isAfterResend: true } });
      }
    } else {
      setResponseResult({ ...apiResult, meta: { isAfterResend: true } });
    }
    setValidationPending(false);

    return apiError;

  };

  /**
   * validate email API
   */
  const makeValidateCall = useCallback(async (validationId, token, isAutoloadCall) => {
    setValidationPending(true);
    //setResponseError(null);
    const [ apiError, apiResult ] = await unwrapPayloadAndError(handleRequestValidation(validationId, token));

    if (isAutoloadCall) {
      setAutoValidation(false);
    }

    console.log(apiError, apiResult);
    //debugger;

    if (apiError) {
      //setResponseError(apiError);
      {
        const { internalError: { response } } = apiError;
        //debugger;
        setResponseResult(response);
      }
      if (isAutoloadCall) {
        handleDropQuery();
      }
    } else {
      setResponseResult(apiResult);
    }

    setValidationPending(false);

    return apiError;
  }, [ handleDropQuery, handleRequestValidation ]);

  /**
   * initial run, if invoked from email
   * for a given validation id (present or not) a preAuth token is obtained
   */
  useEffect(() => {
    (async () => setToken(await queryPreAuth()))();
  }, [ queryPreAuth, queryValidationId ]);

  /**
   *
   * auto-validation
   * when a token is read, an auto-validation is invoked, if conditions are right
   *
   */
  useEffect(() => {
    if (token === false) {
      return;
    }
    if (autoValidation && !validationPending) {
      void makeValidateCall(validationCode, token, true);
    }
  }, [ autoValidation, validationPending, token, makeValidateCall, validationCode ]);

  /**
   * processing of the results
   */
  useEffect(() => {
    if (!responseResult || !responseResult.data) {
      return;
    }

    console.log(responseResult);

    // decide on what to do next, jump to apps or login or unlock email resend
    const {
      meta: { isAfterResend = false } = {},
      data: {
        success, token: authToken, resendTimeout: nextResendTimeout = 110000,

        id,
        name = '',
        email = '',
        emailVerified = false,
        roles,
        authType = '',
      }
    } = responseResult;

    if (success && !isAfterResend) {
      wipePreAuth();
      if (authToken) {
        // redirect to /apps
        persistToken(authToken);
        persistUserData({ id, name, email, emailVerified, roles, authType: authType || null });
        return handleToApps();
      } else {
        // redirect to /login
        //Email validated successfully!
        handleShowNotification && handleShowNotification({ message: `Email validated successfully! You can now log in.` });
        return handleToLogin();
      }
    }

    if (token) {
      // use resendTimeout + time now
      setResendTimestamp(nextResendTimeout + (new Date() - 0));

    } else {
      setSuggestLogin(true);
    }

  }, [ handleShowNotification, handleToApps, handleToLogin, persistToken, persistUserData, responseResult, token, wipePreAuth ]);


  /**
   *
   */
  const onSubmit = useCallback(async ({ validationId }, { reset, focus }) => {
    setValidationCode(validationId);
    const err = await makeValidateCall(validationId, token);
    if (err) {
      if (err.code === 0) {
        setResponseError(err);
        return;
      }
      reset();
      setValidationCode('');
      focus('validationId');
      return { 'validationId': String(err) };
    }
  }, [ makeValidateCall, token ]);

  /**
   *
   */
  const onRequestResend = async () => {
    setResendEnabled(false);
    setResendTimestamp(0);

    const err = await makeResendEmailCall(token);
    if (err) {
      debugger;
      if (err.code === 0) {
        setResponseError(err);
      }
    }
  };

  if (autoValidation) {
    return (<Spinner />);
  }

  return <div className="pt-3">
    <h2>Validate Your Email Address</h2>
    <p className="text">
      An email has been sent to your email address with a code. Copy-paste it in the box or follow the link in the message.</p>
    <ValidationForm onSubmit={ onSubmit } />
    <hr />
    { token && (<>
      <div>Haven't received an email?</div>
      <button disabled={ !resendEnabled } onClick={ onRequestResend } className="btn btn-outline-primary">Resend Validation Email</button>
      {
        (remainingSeconds > 0) && (<>{ ' ' }
          <small>{ ` (Next opportunity in ${ verboseSeconds(remainingSeconds) })` }</small>
        </>)
      }
    </>) }
    { responseError && (<div><code>{ String(responseError) }</code></div>) }

    {/*<div>Response: <br />*/ }
    {/*<code>{ responseResult && JSON.stringify(responseResult, 0, 2) }</code></div>*/ }
    <div className="py-2">
      <Link to={ '/login' }>Login page</Link>
      { suggestLogin && (<p>Please follow this link to authenticate and re-send your validation email.</p>) }
    </div>
  </div>;
};

export default connect((_, { location }) => ({
  queryValidationId: getSearchParam(location.search, 'validationId')
}), (dispatch, location) => ({
  handleRequestValidation: (uuid, token) => dispatch(emailValidation(uuid, token)),
  handleRequestResendEmail: (token) => dispatch(requestResend(token)),
  queryPreAuth: () => dispatch(storage.getItem(PRE_AUTH_KEY)),
  wipePreAuth: () => dispatch(storage.removeItem(PRE_AUTH_KEY)),
  persistToken: token => dispatch(storeToken(token)),
  persistUserData: (userInfo) => dispatch(storage.setItem('userInfo', userInfo)),
  handleDropQuery: () => dispatch(url.replace(location.path)),
  handleToApps: () => dispatch(navigateToApps()),
  handleToLogin: () => dispatch(url.push('/login')),
  handleShowNotification: (...args) => dispatch(notificationMsg(...args)),

}))(EmailValidationPage);
