import { LOCATION_CHANGE } from 'react-router-redux';
import { createAction } from 'redux-actions';

export const lockSignupForm = createAction('SET_INITIAL_SIGNUP_FORM');
export const releaseSignupForm = createAction('RESET_SIGNUP_FORM');

const defaultState = () => ({
  signupLocked: false,
  signupForm: {}
});

const ns = 'ui';

export const accessIsSignupLocked = () => ({ [ns]: state }) => state.signupLocked;

export default {
  [ LOCATION_CHANGE ]: (state, { payload }) => {
    const { pathname } = payload;
    if ('/signup' === pathname) {
      return state;
    }
    const initial = defaultState();
    return {
      ...state,
      signupLocked: initial.signupLocked,
      signupForm: initial.signupForm
    };
  },

  [ lockSignupForm ]: (state, { payload }) => {
    return {
      ...state,
      signupLocked: true,
      signupForm: payload
    };
  },

  [ releaseSignupForm ]: (state) => {
    const initial = defaultState();
    return {
      ...state,
      signupLocked: initial.signupLocked,
      signupForm: initial.signupForm
    };
  },
};
