import * as url from 'react-router-redux';
import { prepareActionTypes } from 'shared-package-frontend/actions';
import { handleErrorsFor } from 'shared-package-frontend/actions-axios';
import { makePayload, makeTimestampedMeta, retagAll } from 'shared-package-frontend/endpoints';

const ApiEndpoints = retagAll({
  login: (login, password) => ({ url: `POST /login`, data: { login, password } }),
  signup: (data) => ({ url: `POST /register`, data }),
  authenticate: (credential, clientId) => ({ url: `POST /authenticate`, data: { credential, clientId } }),
  validateEmail: (validationId, token) => ({ url: `POST /validate-email`, data: { validationId, token } }),
  resendEmail: (token) => ({ url: `POST /resend-validation-email`, data: { token } }),
  resetPassword: (email) => ({ url: `POST /reset-password`, data: { email } }),
  validateResetPassword: (token) => ({ url: `POST /validate-reset-password`, data: { token } }),
  updatePassword: (password, token) => ({ url: `POST /update-password`, data: { password, token } }),
});

handleErrorsFor()([
  [ 0, 'Cannot communicate to server - it can be temporarily down. Or is internet connection missing?' ],
  [ 404, ({ action, axiosAction, dispatch }) => {
    console.log(404, action, axiosAction, '404 Handler: Might be redirecting to /login');
    //dispatch(url.push('/login'));
    void dispatch;
    void url;
  } ],
  [ '*', 'Network error' ]
]);

export const performLogin = handleErrorsFor(prepareActionTypes((login, password) => ({
  ...makePayload(ApiEndpoints.login)(login, password),
  ...makeTimestampedMeta()
}), 3, 'Standard login action'))([
  [ 400, '400 Error code' ],
  [ 403, 'Incorrect login or password' ]
]);

export const performSignup = handleErrorsFor(prepareActionTypes(data => ({
  ...makePayload(ApiEndpoints.signup)(data),
  ...makeTimestampedMeta(data)
}), 3, 'Standard registering action'))([
  [ 400, '']
]);

// see: https://developers.google.com/identity/sign-in/web/server-side-flow
export const exchangeGoogleAuthCode = prepareActionTypes((credential, clientId) => ({
  ...makePayload(ApiEndpoints.authenticate)(credential, clientId),
  ...makeTimestampedMeta()
}), 3, 'Google Auth Code exchange for token');

export const emailValidation = handleErrorsFor(prepareActionTypes((validationId, token) => ({
  ...makePayload(ApiEndpoints.validateEmail)(validationId, token),
  ...makeTimestampedMeta()
}), 3, 'Email validation request'))('*', `Validation code doesn't seem to be correct. Please try another one.`);

export const requestResend = handleErrorsFor(prepareActionTypes((token) => ({
  ...makePayload(ApiEndpoints.resendEmail)(token),
  ...makeTimestampedMeta()
}), 3, 'Resend email request'))('*', `We cannot perform re-send operation at the moment. Please try later.`);

export const requestPasswordReset = handleErrorsFor(prepareActionTypes((email) => ({
  ...makePayload(ApiEndpoints.resetPassword)(email),
  ...makeTimestampedMeta()
}), 3, 'Password reset link request'))('*', `We cannot perform this operation at the moment. Please try again later.`);

export const validateResetPasswordToken = handleErrorsFor(prepareActionTypes((token) => ({
  ...makePayload(ApiEndpoints.validateResetPassword)(token),
  ...makeTimestampedMeta()
}), 3, 'Password reset token validation'))('*', `We cannot perform this operation at the moment. Please try again later.`);

export const updatePassword = handleErrorsFor(prepareActionTypes((password, token) => ({
  ...makePayload(ApiEndpoints.updatePassword)(password, token),
  ...makeTimestampedMeta()
}), 3, 'Password change'))('*', `We cannot perform this operation at the moment. Please try again later.`);
