export function checkPasswordStrength(password) {
  for (const rule of rules) {
    const result = rule(password);
    if (result === true) {
      return {
        isValid: true
      };
    }
  }
  return {
    isValid: false,
    error: `Eight characters long, if it includes a number and a lowercase letter, or 15 characters long with any combination of characters`
  };
}


// rules:
// Eight characters long, if it includes a number and a lowercase letter, or
// 15 characters long with any combination of characters

const rules = [
  pwd => pwd && minLength(pwd, 8) && hasNumber(pwd) && hasLowerCase(pwd),
  pwd => pwd && minLength(pwd, 15)
];

function minLength(pwd, length) {
  return pwd.trim().length >= length;
}

function hasNumber(pwd) {
  return /\d/.test(pwd);
}

function hasLowerCase(pwd) {
  for (const ch of Array.from(pwd)) {
    if (ch.toUpperCase() !== ch) {
      return true;
    }
  }
  return false;
}
