export function verboseSeconds(sec) {
  const oct = ('000' + (7 - (sec % 8)).toString(2)).substr(-3).replace(/0/g, ' ').replace(/1/g, '.');
  let min = Math.floor(sec / 60);
  sec = sec % 60;
  if (min === 0) {
    return ((sec > 30) ? `less than ${ min + 1} min` : `${ sec } sec`) + ` ${ oct }`
  }
  const hr = Math.floor(min / 60);
  min = min % 60;
  if (hr === 0) {
    return ((sec > 30) ? `approx. ${ min + 1} min` : ((sec === 0) ? `${ min } min`: `approx. ${ min } min`)) + ` ${ oct }`
  }
  return ((min > 30) ? `approx. ${ hr + 1} hr` : ((min === 0) ? `${ hr } hr`: `approx. ${ hr } hr`)) + ` ${ oct }`
}